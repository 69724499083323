import { useEffect } from 'react';
import * as React from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import MobileNavigation from '../components/MobileNavigation/MobileNavigation';
import Sidebar from '../components/Sidebar/Sidebar.tsx';
import Toast from '../components/Toast/Toast';
import { fetchFoodListFoodItemsAction } from '../data/actions/foodListFoodItemsAction';
import { fetchFoodListsAction } from '../data/actions/foodListsAction';
import { fetchSelfAction, fetchUsersAction } from '../data/actions/usersAction';
import { useStyles } from './LayoutStyles';

// import { ThemeType } from '../theme.ts';

const Layout = ({ children, login, fetchSelf, user, modals, fetchFoodLists, foodLists }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const fetchOrLogout = () => {
    if (localStorage.getItem('accessToken')) {
      fetchSelf();
    } else {
      console.log('no token');
      navigate('/login');
    }
  };

  // Always fetch self - this is used in the header
  // Check user is logged in. If not logged in, redirect to login screen
  useEffect(() => {
    fetchOrLogout();
  }, []);

  // Refetch if needed
  useEffect(() => {
    if (foodLists?.shouldFetch) {
      // fetchFoodLists([]);
    }
  }, [foodLists?.shouldFetch]);

  // Wait for user
  if (user?.loading) {
    return (
      <div style={{ backgroundColor: '#303030', color: '#909090', height: '100dvh' }}>
        Loading...
      </div>
    );
  }

  if (!user?.success) {
    console.log('has not loaded user');
    return <></>;
  }

  // side bar
  const styles = {
    colorScheme: 'dark light',
    maxWidth: '200px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between', // width: '100vw',
    // height: '60px',
    // maxHeight: '60px',
    flexShrink: 0,
    alignItems: 'flex-start', // padding: '0 24px',
    // backgroundColor: 'white',
    backgroundColor: theme?.backgroundBlue,
    padding: '24px',
  };

  return (
    <div
      style={{
        // page background
        display: 'flex',
        flexDirection: 'column',
        height: '100dvh',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: theme?.almostWhite,
        // backgroundColor: '#F6F6F6',
      }}
    >
      <div
        style={{
          // horizontal page layout
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
        }}
      >
        <Sidebar />

        {/*TODO salvage old sidebar*/}
        {/*<div style={styles}>*/}
        {/*  <Link*/}
        {/*    to={'/'}*/}
        {/*    style={{*/}
        {/*      textDecoration: 'none',*/}
        {/*      // color: '#ffffff88',*/}
        {/*      // color: '#04AA6D',*/}
        {/*      // color: '#000000dd',*/}
        {/*      // height: '100%',*/}
        {/*      display: 'flex',*/}
        {/*      alignItems: 'center',*/}
        {/*      // padding: '0 24px',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        fontWeight: '600',*/}
        {/*        fontSize: '16px',*/}
        {/*        color: 'blue',*/}
        {/*        filter: 'brightness(100%)',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Progress Rater*/}
        {/*    </div>*/}
        {/*  </Link>*/}
        {/*  /!*<div>{version}</div>*!/*/}
        {/*</div>*/}

        <div
          style={{
            width: '100%',
            // backgroundColor: theme?.almostWhite,
            // maxWidth: '1232px',
            overflowY: 'scroll',
          }}
        >
          <div
            style={{
              width: '100%',
              // backgroundColor: theme?.almostWhite,
              maxWidth: '1232px',
              // overflowY: 'scroll',
            }}
          >
            {/*Header*/}
            {/*<Outlet />*/}
            {children}
            <Toast />
          </div>
        </div>
      </div>

      {/*TOOD turn into component*/}
      <MobileNavigation />
      {/*<div className={classes.mobileNavigation}>*/}
      {/*  <div>Home</div>*/}
      {/*  <div>Something</div>*/}
      {/*  <div>Log out</div>*/}
      {/*</div>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  user: state.user,
  modals: state.modals,
  foodLists: state.foodLists,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSelf: fetchSelfAction,
      fetchFoodLists: fetchFoodListsAction,
      fetchFoodListFoodItems: fetchFoodListFoodItemsAction,
      fetchUsers: fetchUsersAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
