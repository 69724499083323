import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createFeedAttemptAction } from '../../data/actions/feedAttemptsAction';
import { Checkbox } from '../Checkbox/Checkbox';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { TextArea } from '../TextArea/TextArea';
import { Modal } from './Modal';

const AddFeedAttemptModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  createFeedAttempt,
  feedAttempts,
  foodId,
  foodItem,
}) => {
  const [hadReaction, setHadReaction] = useState(false);
  const [image, setImage] = useState(null);
  const [wasSuccessful, setWasSuccessful] = useState(true);
  const [comment, setComment] = useState('');
  // const [attemptedAt, setAttemptedAt] = useState('');

  const [imageError, setImageError] = useState('');
  const [commentError, setCommentError] = useState('');

  const [posting, setPosting] = useState(false);

  // When opening, reset the state

  const validate = () => {
    // TODO validation fields
    let valid = true;

    const imageUploadLimit = 100 * 1000 * 1000; // 100MB TODO set this in env
    if (image && !image?.size >= imageUploadLimit) {
      setImageError('File size must be less than 100MB');
      valid = false;
    }

    if (!valid) {
      console.log('form is not valid');
    }

    return valid;
  };

  const handleSubmit = () => {
    const valid = validate();

    if (!valid) {
      return;
    }

    createFeedAttempt({
      // eslint-disable-next-line camelcase
      had_reaction: hadReaction,
      // eslint-disable-next-line camelcase
      was_successful: wasSuccessful,
      comment: comment,
      // eslint-disable-next-line camelcase
      food_item_id: foodId,
      // attempted_at: attemptedAt,
      image,
    });

    setPosting(true);
  };

  // Close the modal when finished posting
  useEffect(() => {
    // TODO need a better way to have a callback for this specific feed attempt being posted
    if (posting && !feedAttempts?.posting && feedAttempts?.success) {
      setPosting(false);
      closeModal();
    }
  }, [feedAttempts?.posting, feedAttempts?.success]);

  const isDisabled = () => feedAttempts?.posting;

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      setWasSuccessful(true);
      setImage(null);
      setComment('');
      setHadReaction(false);
      setCommentError('');
      setImageError('');
      setPosting(false);
    });
    onClose();
  };

  // TODO show loading spinner while it is being created

  return (
    <Modal
      showCloseButton
      showCancelButton
      title={`Tried to eat ${foodItem.name.toLowerCase()}!`}
      submitText="Add new feed attempt"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={feedAttempts?.posting}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Checkbox
          label="Successfully ate"
          style={{ maxWidth: '400px' }}
          value={wasSuccessful}
          onClick={() => setWasSuccessful(!wasSuccessful)}
        />
        <Checkbox
          label="Had an allergic reaction"
          style={{ maxWidth: '400px' }}
          value={hadReaction}
          onClick={() => setHadReaction(!hadReaction)}
        />
        <ImageUpload
          value={image}
          onChange={setImage}
          label="Pic of the attempt"
          style={{ maxWidth: '400px' }}
          error={imageError}
        />
        <TextArea
          label="Comments"
          placeholder="Type your comments here..."
          value={comment}
          onChange={setComment}
          error={commentError}
          // autofocus
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  feedAttempts: state.feedAttempts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFeedAttempt: createFeedAttemptAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddFeedAttemptModal);
