import axios from 'axios';

import { clearUserLoginAction } from './actions/loginAction';
import { getStore } from './store';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 1000,
  headers: { 'X-Custom-Header': 'foobar' },
});

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

// TODO if api responds with unauthenticated, wipe token and redirect to login screen

const handleError = (error) => {
  console.log('error: ', error.response);

  // If unauthenticated, redirect to login
  if (error?.response?.status === 401) {
    getStore().dispatch(clearUserLoginAction());
  }

  const multipleErrors = error.response?.data?.errors;
  let errorList = [];

  // Reduce multiple errors (e.g. invalid email, password) down to a list of errors
  if (multipleErrors) {
    let allErrors = [];
    Object.keys(multipleErrors).forEach((loginError) => {
      allErrors = [...allErrors, ...multipleErrors[loginError]];
    });

    errorList = allErrors;
  } else {
    errorList = [error.response?.data?.error];
  }

  return errorList;
};

const getParams = (includes = [], filters = []) => {
  const params = {};

  if (includes?.length > 0) {
    params.include = includes?.join(',');
  }

  if (filters?.length > 0) {
    params.q = JSON.stringify(filters);
  }

  return { params };
};

const fetchItem = async (url, config, includes = [], filters = []) => {
  const responseData = {
    data: null,
    errors: [],
  };

  await axios
    .get(url, { ...config, ...getParams(includes, filters) })
    .then((response) => {
      console.log('response: ', response);
      responseData.data = response.data;
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  return responseData;
};

const post = async (url, data, config, includes) => {
  const responseData = {
    data: null,
    errors: [],
  };

  await axios
    .post(url, data, { ...config, ...getParams(includes) })
    .then((response) => {
      console.log('response: ', response);
      responseData.data = response.data;
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  // TODO if error, show on app somewhere

  return responseData;
};

const patchItem = async (url, id, data, config, includes) => {
  const responseData = {
    data: null,
    errors: [],
  };

  await axios
    .patch(`${url}/${id}`, data, { ...config, ...getParams(includes) })
    .then((response) => {
      console.log('patch response: ', response);
      responseData.data = response.data;
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  // TODO if error, show on app somewhere

  return responseData;
};

const deleteItem = async (url, id, config) => {
  const responseData = {
    errors: [],
  };

  await axios
    .delete(`${url}/${id}`, { ...config })
    .then((response) => {
      console.log('delete response: ', response);
    })
    .catch((error) => {
      responseData.errors = handleError(error);
    });

  // TODO if error, show on app somewhere

  return responseData;
};

const getAuthConfig = () => {
  const accessToken = localStorage.getItem('accessToken');

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

// Endpoints
export const login = (data) => post('/login', data);
export const signup = (data) => post('/register', data);
// export const fetchGameUsers = (includes = [], filters = []) =>
//   fetchItem('/game_users', getAuthConfig(), includes, filters);

// Users
export const fetchSelf = () => fetchItem('/users/me', getAuthConfig());

// // TODO admin only
export const fetchUser = (userId) => fetchItem(`/users/${userId}`, getAuthConfig());
export const fetchUsers = () => fetchItem(`/users`, getAuthConfig());

// Food items
export const createFoodItem = (data, includes = []) =>
  post('/food_items', data, getAuthConfig(), includes);
export const updateFoodItem = (data, id, includes = []) =>
  patchItem('/food_items', id, data, getAuthConfig(), includes);
export const fetchFoodItem = (foodItemId, includes = []) =>
  fetchItem(`/food_items/${foodItemId}`, getAuthConfig(), includes);
export const fetchFoodItems = (includes = [], filters = []) =>
  fetchItem('/food_items', getAuthConfig(), includes, filters);

// Images
export const uploadImage = (data, includes = []) =>
  post('/images', data, getAuthConfig(), includes);

// GameUsers
export const createGameUser = (data, includes = []) =>
  post('/game_users', data, getAuthConfig(), includes);
export const updateGameUser = (data, id, includes = []) =>
  patchItem(`/game_users`, id, data, getAuthConfig(), includes);

// Feed Attempts
export const fetchFeedAttempts = (includes = [], filters = []) =>
  fetchItem('/feed_attempts', getAuthConfig(), includes, filters);
export const createFeedAttempt = (data, includes = []) =>
  post('/feed_attempts', data, getAuthConfig(), includes);
export const updateFeedAttempt = (data, id, includes = []) =>
  patchItem(`/feed_attempts`, id, data, getAuthConfig(), includes);

// Food Lists
export const fetchFoodLists = (includes = [], filters = []) =>
  fetchItem('/food_lists', getAuthConfig(), includes, filters);
export const createFoodList = (data, includes = []) =>
  post('/food_lists', data, getAuthConfig(), includes);
export const updateFoodList = (data, id, includes = []) =>
  patchItem(`/food_lists`, id, data, getAuthConfig(), includes);
export const deleteFoodList = (id) => deleteItem(`/food_lists`, id, getAuthConfig());

// Food categories
export const fetchFoodCategories = (includes = [], filters = []) =>
  fetchItem('/food_categories', getAuthConfig(), includes, filters);
export const createFoodCategory = (data, includes = []) =>
  post('/food_categories', data, getAuthConfig(), includes);
export const updateFoodCategory = (data, id, includes = []) =>
  patchItem(`/food_categories`, id, data, getAuthConfig(), includes);
export const deleteFoodCategory = (id) => deleteItem(`/food_categories`, id, getAuthConfig());

// Babies
export const fetchBabies = (includes = [], filters = []) =>
  fetchItem('/babies', getAuthConfig(), includes, filters);
export const createBaby = (data, includes = []) => post('/babies', data, getAuthConfig(), includes);
export const updateBaby = (data, id, includes = []) =>
  patchItem(`/babies`, id, data, getAuthConfig(), includes);
export const deleteBaby = (id) => deleteItem(`/babies`, id, getAuthConfig());

// Food list food items
export const fetchFoodListFoodItems = (includes = [], filters = []) =>
  fetchItem('/food_list_food_items', getAuthConfig(), includes, filters);
export const createFoodListFoodItem = (data, includes = []) =>
  post('/food_list_food_items', data, getAuthConfig(), includes);
export const updateFoodListFoodItem = (data, id, includes = []) =>
  patchItem(`/food_list_food_items`, id, data, getAuthConfig(), includes);
