import { mergeArrays, replaceItem } from '../../helpers/helpers';
import * as type from '../actions/types';

const initialState = {
  errors: [],
  fetching: false,
  posting: false,
  success: false,
  shouldFetch: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_FOOD_LISTS_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        shouldFetch: false,
        success: false,
        errors: [],
      };
    case type.FETCH_FOOD_LISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        shouldFetch: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, 'id', true),
      };
    case type.FETCH_FOOD_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        shouldFetch: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_FOOD_LIST_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.CREATE_FOOD_LIST_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_FOOD_LIST_SUCCESS:
      console.log('game list was created', action?.payload, state);
      const newItem = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, newItem, 'id', true),
      };
    case type.UPDATE_FOOD_LIST_SUCCESS:
      console.log('game list was updated', action?.payload, state);
      const updatedItem = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, updatedItem, 'id', true),
      };
    case type.UPDATE_FOOD_LIST_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.UPDATE_FOOD_LIST_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.BATCH_UPDATE_FOOD_LISTS_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.BATCH_UPDATE_FOOD_LISTS_SUCCESS:
      return {
        ...state,
        shouldFetch: true,
        posting: false,
        success: true,
        errors: [],
      };
    case type.BATCH_UPDATE_FOOD_LISTS_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
