import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as FailureIcon } from '../../assets/icons/exclamation.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';

// TODO support error messages
const Toast = ({ style, waitTime, messages }) => {
  const theme = useTheme();

  const [isShowing, setIsShowing] = useState(false);
  const [animateHiding, setAnimateHiding] = useState(false);

  // Show the message if it changes in some way
  // TODO message content may not necessarily change
  // TODO handle multiple messages being queued
  useEffect(() => {
    if (messages?.message) {
      setIsShowing(true);
      setTimeout(() => setIsShowing(false), waitTime);
    }
  }, [messages?.id]);

  // If no longer showing, fade out
  useEffect(() => {
    if (!isShowing) {
      setAnimateHiding(true);
      setTimeout(() => setAnimateHiding(false), 0.5);
    }
  }, [isShowing]);

  const close = () => {
    setIsShowing(false);
  };

  const styles = {
    container: {
      ...style,
      visibility: isShowing || animateHiding,
      position: 'fixed',
      bottom: '24px',
      ...style?.container,
    },
    snackbar: {
      minWidth: '300px',
      // height: '20px',
      backgroundColor: '#333333',
      borderRadius: '4px',
      color: '#FFFFFF',
      padding: '18px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: '500',
      gap: '12px',
      ...style?.snackbar,
    },
    snackbarContent: {
      display: 'flex',
      gap: '18px',
      whiteSpace: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.typography.featured,
      ...style?.snackbarContent,
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      ...theme.components.clickable,
      ...style?.closeButton,
    },
    show: {
      visibility: 'visible',
      opacity: 1,
      bottom: '24px',
      transition: 'bottom 0.5s, opacity 0.5s',
      ...style?.show,
    },
    hide: {
      opacity: 0,
      bottom: 0,
      transition: 'bottom 0.5s, opacity 0.5s',
      ...style?.hide,
    },
  };

  return (
    <div
      style={{
        ...styles.container,
        ...(isShowing ? styles.show : styles.hide),
        // ...styles.show,
      }}
    >
      <div style={{ ...styles.snackbar }}>
        <div style={styles.snackbarContent}>
          {messages.severity === 'error' ? (
            <FailureIcon color="#CC1F64" />
          ) : (
            <SuccessIcon color="#1FCC64" />
          )}
          {messages?.message}
        </div>
        <div style={styles.closeButton} onClick={close}>
          <CloseIcon style={{ marginLeft: 'auto', width: '24px' }} />
        </div>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  activeMessage: 'Game successfully created',
  waitTime: 5000,
};

const mapStateToProps = (state) => ({
  messages: state.toasts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
