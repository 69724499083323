import { combineReducers } from 'redux';

import babiesReducer from './babiesReducer';
import feedAttemptsReducer from './feedAttemptsReducer';
import foodCategoriesReducer from './foodCategoriesReducer';
import foodItemsReducer from './foodItemsReducer';
import foodListFoodItemsReducer from './foodListFoodItemsReducer';
import foodListsReducer from './foodListsReducer';
import gameUsersReducer from './gameUsersReducer';
import loginReducer from './loginReducer';
import modalsReducer from './modalsReducer';
import signupReducer from './signupReducer';
import toastReducer from './toastReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';

const appReducer = combineReducers({
  babiesReducer: babiesReducer,
  foodCategories: foodCategoriesReducer,
  foodItems: foodItemsReducer,
  foodLists: foodListsReducer,
  foodListFoodItems: foodListFoodItemsReducer,
  gameUsers: gameUsersReducer,
  login: loginReducer,
  feedAttempts: feedAttemptsReducer,
  signup: signupReducer,
  toasts: toastReducer,
  user: userReducer,
  users: usersReducer,
  modals: modalsReducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
