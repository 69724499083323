import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadFromLocalStorage, saveToLocalStorage } from '../../data/localStorage.js';
import { sortObjectList } from '../../helpers/helpers';
import { groupByProperty } from '../../utils/helpers';
import { TextButton } from '../Button/TextButton';
import AddCategoryModal from '../Modal/AddCategoryModal';
import { SortBar } from '../SortBar/SortBar';
import FoodCategory from './FoodCategory/FoodCategory';

const FoodList = ({ heading, style, loading, foodItems, foodCategories, feedAttempts }) => {
  const theme = useTheme();

  const styles = {
    container: {
      ...style,
      // marginTop: "24px",
      display: 'flex',
      gap: '16px',
      ...style?.container,
    },
    heading: {
      ...style?.heading,
    },
    filters: {
      ...style?.filters,
    },
    list: {
      flex: 1,
      ...style?.list,
    },
    listWrapper: {
      marginTop: '16px',
      display: 'flex',
      alignItems: 'flex-start',
      // flexBasis: "50%",
      flexWrap: 'wrap',
      // flex: 1,
      gap: '16px',
      ...style?.listWrapper,
    },
  };

  const [showAddFoodModal, setShowAddFoodModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

  // Filters
  const [sortMode, setSortMode] = useState(loadFromLocalStorage('sortMode', 'default'));

  const handleChangeSortMode = (newValue) => {
    saveToLocalStorage('sortMode', newValue);
    setSortMode(newValue);
  };

  // Sort the food into their categories
  const foodItemsByCategory = useMemo(() => {
    const sortFoodList = (items) => {
      const feedAttemptsForItem = (foodItem) => {
        return sortObjectList(
          feedAttempts?.data?.filter((attempt) => attempt?.food_item_id === foodItem?.id),
          'created_at',
          true,
        );
      };

      const itemsWithAttempts = items.map((item) => {
        // const attemptsForItem = feedAttemptsForItem(item);

        return {
          ...item,
          attempts: feedAttemptsForItem(item),
        };
      });

      // if (sortMode === 'default') {
      //   // return sortObjectList(items, 'created_at', true);
      //   return sortObjectList(items, 'updated_at', true);
      // }

      if (sortMode === 'newest') {
        // return sortObjectList(items, 'created_at', true);
        return sortObjectList(items, 'created_at', true);
      }

      if (sortMode === 'oldest') {
        // return sortObjectList(items, 'created_at', true);
        return sortObjectList(items, 'created_at', false);
      }

      if (sortMode === 'completion') {
        return itemsWithAttempts.slice(0).sort((a, b) => b?.attempts?.length - a?.attempts?.length);
      }

      if (sortMode === 'recent' || sortMode === 'not_recent') {
        const sorted = itemsWithAttempts
          .map((item) => ({
            ...item,
            mostRecentFeedTime: (item?.attempts ?? [])?.reduce((accum, attempt) => {
              if (accum === null) {
                return dayjs(attempt?.created_at); // TODO use attempted at too
              }

              return dayjs(attempt?.created_at).isAfter(accum) ? dayjs(attempt?.created_at) : accum;
            }, null),
          }))
          .sort((a, b) =>
            a?.mostRecentFeedTime > b?.mostRecentFeedTime
              ? sortMode === 'recent'
                ? -1
                : 1
              : sortMode === 'recent'
              ? 1
              : -1,
          );

        return sorted;
        // return sortObjectList(items, 'completion', true);
      }

      return sortObjectList(items, 'name', false);
    };

    const sortedFoodItems = sortFoodList(foodItems?.data);

    const foodByCategory = groupByProperty(sortedFoodItems, 'food_category_id');

    return foodByCategory;
  }, [foodItems?.data, foodCategories?.data, feedAttempts.data, sortMode]);

  return (
    <div style={styles.container}>
      <div style={styles.list}>
        <div style={{ ...theme.typography.h1, ...styles.heading }}>{heading}</div>
        <div style={{ marginTop: '24px' }}>
          <SortBar
            setShowAddFoodModal={setShowAddFoodModal}
            setShowAddCategoryModal={setShowAddCategoryModal}
            onChange={handleChangeSortMode}
            sortMode={sortMode}
          />
        </div>
        {loading ? (
          <div style={{ marginTop: '16px' }}>Loading</div>
        ) : foodCategories?.length === 0 ? (
          <div
            style={{
              textAlign: 'center',
              maxWidth: '500px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '128px',
            }}
          >
            <div style={theme.typography.h1}>Nothing to show</div>
            <div style={{ ...theme.typography.featured, marginTop: '8px' }}>
              <TextButton
                onClick={() => setShowAddFoodModal(true)}
                style={{ container: { padding: 0, color: '#2626CD' } }}
              >
                Start by adding a category of food
              </TextButton>
            </div>
          </div>
        ) : (
          <div style={styles.listWrapper}>
            {foodCategories.data.map((category) => (
              <FoodCategory
                key={`category-${category.id}`}
                foodCategory={category}
                foodItems={foodItemsByCategory?.[category.id] ?? []}
              />
              // <div key={`category-${category.id}`}>
              //   <div>{category.name}</div>
              //   <div>
              //     {(foodItemsByCategory?.[category.id] ?? []).map((foodItem) => (
              //       <GameCard
              //         key={`category-${category.id}-food-${foodItem?.id}`}
              //         foodItem={foodItem}
              //       />
              //     ))}
              //   </div>
              // </div>
            ))}
          </div>
        )}
      </div>

      {/*<AddFoodModal*/}
      {/*  showCloseButton*/}
      {/*  title="Add new food"*/}
      {/*  isOpen={showAddFoodModal}*/}
      {/*  onClose={() => setShowAddFoodModal(false)}*/}
      {/*/>*/}

      <AddCategoryModal
        showCloseButton
        title="Add new category"
        isOpen={showAddCategoryModal}
        onClose={() => setShowAddCategoryModal(false)}
      />
    </div>
  );
};

FoodList.defaultProps = {
  games: [],
  lists: [],
  activeUserAtTop: true,
};

FoodList.propTypes = {
  activeUserAtTop: PropTypes.bool,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  lists: PropTypes.arrayOf(PropTypes.shape({})),
  activeUser: PropTypes.shape({}).isRequired,
  // foodListFoodItems: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  activeUser: state.user,
  foodCategories: state.foodCategories,
  foodLists: state.foodLists,
  foodItems: state.foodItems,
  feedAttempts: state.feedAttempts,
  foodListFoodItems: state.foodListFoodItems,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FoodList);
