import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateFoodCategoryAction } from '../../data/actions/foodCategoriesAction';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { TextField } from '../TextField/TextField';
import { Modal } from './Modal';

const EditFoodCategoryModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  updateFoodCategory,
  foodCategories,
  foodCategory,
}) => {
  const [name, setName] = useState(foodCategory?.name);
  const [color, setColor] = useState(foodCategory?.color);

  const [nameError, setNameError] = useState('');
  const [colorError, setColorError] = useState('');

  const [posting, setPosting] = useState(false);

  // When opening, reset the state
  useEffect(() => {
    if (isOpen) {
      setName(foodCategory?.name);
      setColor(foodCategory?.color);
    }
  }, [isOpen]);

  const validate = () => {
    // TODO validation fields
    let valid = true;

    if (!name) {
      setNameError('You must set a name for the food!');
      valid = false;
    }

    if (!color) {
      setNameError('You must set a color');
      valid = false;
    }

    if (!valid) {
      console.log('form is not valid');
    }

    return valid;
  };

  const handleSubmit = () => {
    const valid = validate();

    if (!valid) {
      return;
    }

    console.log('ffff', foodCategory);

    // TODO only update the fields that are different
    updateFoodCategory(foodCategory?.id, {
      name,
      color,
    });

    setPosting(true);
  };

  // Close the modal when the foodCategory is finished posting
  useEffect(() => {
    if (posting && !foodCategories?.posting && foodCategories?.success) {
      setPosting(false);
      closeModal();
    }
  }, [foodCategories?.posting, foodCategories?.success]);

  const isDisabled = () => foodCategories?.posting;

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      setName(null);
      setNameError(null);
      setColor('');
      setColorError('');
      setPosting(false);
    });
    onClose();
  };

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Edit category"
      submitText="Save changes"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={foodCategories?.posting}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextField label={'Name'} value={name} onChange={setName} />
        {/*<TextField label={'Color'} value={color} onChange={setColor} />*/}

        <ColorPicker label={'Color'} value={color} onChange={setColor} />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  foodCategories: state.foodCategories,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFoodCategory: updateFoodCategoryAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditFoodCategoryModal);
