import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { userLogoutAction } from '../data/actions/loginAction';

const Logout = ({ userLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    userLogout();
    navigate('/login');
  }, []);

  return <div>Logging out...</div>;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogout: userLogoutAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
