import { createUseStyles } from 'react-jss';

import { ThemeType } from '../theme.ts';

export const useStyles = createUseStyles(
  (theme: ThemeType) => ({
    mobileNavigation: {
      backgroundColor: theme.almostWhite,
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '50px',
      borderTop: '1px solid black',
      width: '100dvw',
      marginTop: '50px', // So it doesn't overlap page content at bottom
      padding: '12px',
      zIndex: '100',
      transition: 'bottom 0.4s',

      ['@media only screen and (min-width: 600px)']: {
        // display: 'none',
        bottom: '-50px',

        animation: 'hideAnimation 0s ease-in 1s forwards',
        animationFillMode: 'forwards',
      },
    },
  }),
  { name: 'Layout' },
);
