import { pluck } from '../../helpers/helpers';
import { createFeedAttempt, fetchFeedAttempts, updateFeedAttempt, uploadImage } from '../apiClient';
import * as type from './types';

export const fetchFeedAttemptsAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_FEED_ATTEMPTS_PENDING,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await fetchFeedAttempts(includes, filters);

        if (response.errors.length > 0) {
          console.log('FETCH_FEED_ATTEMPTS_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_FEED_ATTEMPTS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_FEED_ATTEMPTS_SUCCESS', response);
          dispatch({
            type: type.FETCH_FEED_ATTEMPTS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_FEED_ATTEMPTS_FAILURE', error);
        dispatch({
          type: type.FETCH_FEED_ATTEMPTS_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };

export const createFeedAttemptAction = (feedAttemptData) => (dispatch) => {
  console.log('preparing to create feedAttempt', feedAttemptData);

  dispatch({
    type: type.CREATE_FEED_ATTEMPT_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;
      let imageResponse = null;

      if (feedAttemptData?.image) {
        // Upload image
        const imageData = {
          name: feedAttemptData?.image?.name,
          format: feedAttemptData?.image?.format,
          width: feedAttemptData?.image?.width,
          height: feedAttemptData?.image?.height,
          filesize: feedAttemptData?.image?.size,
          data: feedAttemptData?.image?.data,
          is_public: false,
        };
        imageResponse = await uploadImage(imageData);

        if (imageResponse.errors.length > 0) {
          dispatch({
            type: type.CREATE_FOOD_ITEM_FAILURE,
            payload: imageResponse.errors,
          });
          return;
        }
      }

      // Create the feedAttempt
      response = await createFeedAttempt(
        {
          ...pluck(feedAttemptData, ['had_reaction', 'was_successful', 'comment', 'food_item_id']),
          image_id: imageResponse?.data?.data?.id,
        },
        ['images'],
      );

      if (response.errors.length > 0) {
        console.log('CREATE_FEED_ATTEMPT_FAILURE', response.errors);
        dispatch({
          type: type.CREATE_FEED_ATTEMPT_FAILURE,
          payload: response.errors,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: 'Failed to update feedAttempt',
            severity: 'error',
            id: `CREATE_FEED_ATTEMPT_FAILURE_${new Date().getTime()}`,
          },
        });
      } else {
        console.log('CREATE_FEED_ATTEMPT_SUCCESS', response);
        dispatch({
          type: type.CREATE_FEED_ATTEMPT_SUCCESS,
          payload: {
            ...response?.data,
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: 'FeedAttempt created',
            severity: 'success',
            id: `CREATE_FEED_ATTEMPT_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log('CREATE_FEED_ATTEMPT_FAILURE', error);
      dispatch({
        type: type.CREATE_FEED_ATTEMPT_FAILURE,
        payload: [error.message],
      });

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: 'Failed to update feedAttempt',
          severity: 'error',
          id: `CREATE_FEED_ATTEMPT_FAILURE_${new Date().getTime()}`,
        },
      });
    }
  };

  postData();
};

export const updateFeedAttemptAction = (feedAttemptData, id) => (dispatch) => {
  console.log('preparing to create feedAttempt', feedAttemptData);

  dispatch({
    type: type.UPDATE_FEED_ATTEMPT_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;

      let imageResponse = null;

      if (feedAttemptData?.image) {
        // TODO Image stuff should be its own method somewhere
        // Upload image
        const imageData = {
          name: feedAttemptData?.image?.name,
          format: feedAttemptData?.image?.format,
          width: feedAttemptData?.image?.width,
          height: feedAttemptData?.image?.height,
          filesize: feedAttemptData?.image?.size,
          data: feedAttemptData?.image?.data,
          is_public: true,
        };
        imageResponse = await uploadImage(imageData);

        if (imageResponse.errors.length > 0) {
          dispatch({
            type: type.UPDATE_FEED_ATTEMPT_FAILURE,
            payload: imageResponse.errors,
          });
          return;
        }
      }

      const newFeedAttemptData = pluck(feedAttemptData, [
        'had_reaction',
        'was_successful',
        'comment',
        'food_item_id',
        'image',
      ]);

      if (imageResponse) {
        newFeedAttemptData.image_id = imageResponse?.data?.data?.id;
      }

      // Update the feedAttempt
      response = await updateFeedAttempt(newFeedAttemptData, id, ['user']);

      if (response.errors.length > 0) {
        console.log('UPDATE_FEED_ATTEMPT_FAILURE', response.errors);
        dispatch({
          type: type.UPDATE_FEED_ATTEMPT_FAILURE,
          payload: response.errors,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: 'Failed to update feedAttempt',
            severity: 'error',
            id: `UPDATE_FEED_ATTEMPT_FAILURE_${new Date().getTime()}`,
          },
        });
      } else {
        console.log('UPDATE_FEED_ATTEMPT_SUCCESS', response);
        dispatch({
          type: type.UPDATE_FEED_ATTEMPT_SUCCESS,
          payload: {
            ...response?.data,
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: 'Feed attempt updated',
            severity: 'success',
            id: `UPDATE_FEED_ATTEMPT_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log('UPDATE_FEED_ATTEMPT_FAILURE', error);
      dispatch({
        type: type.UPDATE_FEED_ATTEMPT_FAILURE,
        payload: [error.message],
      });

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: 'Failed to update feed attempt',
          severity: 'error',
          id: `UPDATE_FEED_ATTEMPT_FAILURE_${new Date().getTime()}`,
        },
      });
    }
  };

  postData();
};

export const createOrUpdateFeedAttemptAction = (feedAttempt) => (dispatch) => {
  if (!feedAttempt?.id) {
    dispatch(createFeedAttemptAction(feedAttempt));
  } else {
    dispatch(updateFeedAttemptAction(feedAttempt, feedAttempt?.id));
  }
};
