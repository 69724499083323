import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createFoodCategoryAction } from '../../data/actions/foodCategoriesAction';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { TextField } from '../TextField/TextField';
import { Modal } from './Modal';

const AddFoodCategoryModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  createFoodCategory,
  foodCategories,
}) => {
  const [name, setName] = useState(null);
  const [color, setColor] = useState(null);

  const [nameError, setNameError] = useState('');
  const [colorError, setColorError] = useState('');

  const [posting, setPosting] = useState(false);

  // When opening, reset the state

  const validate = () => {
    // TODO validation fields
    let valid = true;

    if (!name) {
      setNameError('You must set a name for the food!');
      valid = false;
    }

    if (!color) {
      setNameError('You must set a color');
      valid = false;
    }

    if (!valid) {
      console.log('form is not valid');
    }

    return valid;
  };

  const handleSubmit = () => {
    const valid = validate();

    if (!valid) {
      return;
    }

    createFoodCategory({
      name: name,
      color,
    });

    setPosting(true);
  };

  // Close the modal when finished posting
  useEffect(() => {
    // TODO need a better way to have a callback for this specific feed attempt being posted
    if (posting && !foodCategories?.posting && foodCategories?.success) {
      setPosting(false);
      closeModal();
    }
  }, [foodCategories?.posting, foodCategories?.success]);

  const isDisabled = () => foodCategories?.posting;

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      setName('');
      setNameError('');
      setColor('');
      setColorError('');
      setPosting(false);
    });
    onClose();
  };

  // TODO show loading spinner while it is being created

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Add new category"
      submitText="Add new category"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={foodCategories?.posting}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {/*<ImageUpload*/}
        {/*  value={image}*/}
        {/*  onChange={setImage}*/}
        {/*  label="Image"*/}
        {/*  style={{ maxWidth: '400px' }}*/}
        {/*  error={imageError}*/}
        {/*/>*/}
        <TextField label={'Name'} value={name} onChange={setName} />

        <ColorPicker label={'Color'} value={color} onChange={setColor} />

        {/*<TextField label={'Colour'} value={color} onChange={setColor} />*/}

        {/*<label htmlFor="favcolor">Colour:</label>*/}
        {/*<input*/}
        {/*  type="color"*/}
        {/*  id="favcolor"*/}
        {/*  value={color}*/}
        {/*  style={*/}
        {/*    {*/}
        {/*      // padding: '0',*/}
        {/*      // width: '40px',*/}
        {/*      // height: '40px',*/}
        {/*      // // blockSize: '40px',*/}
        {/*      // // inlineSize: '40px',*/}
        {/*      // margin: '0',*/}
        {/*      // borderWidth: '0',*/}
        {/*      // backgroundColor: 'transparent',*/}
        {/*      // // borderImage: 'none',*/}
        {/*      // cursor: 'pointer',*/}
        {/*      // border: 'none',*/}
        {/*      // borderStyle: 'unset',*/}
        {/*      // outline: '1px solid blue',*/}
        {/*    }*/}
        {/*  }*/}
        {/*/>*/}
        {/*<TextArea*/}
        {/*  label="Comments"*/}
        {/*  placeholder="Type your comments here..."*/}
        {/*  value={comment}*/}
        {/*  onChange={setComment}*/}
        {/*  error={commentError}*/}
        {/*  autofocus*/}
        {/*/>*/}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  foodCategories: state.foodCategories,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFoodCategory: createFoodCategoryAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddFoodCategoryModal);
