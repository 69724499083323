import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as TickIcon } from '../../assets/icons/tick.svg';
import { isVoid, pluralise, range, sortObjectList } from '../../utils/helpers';
import { ActionMenuButton } from '../ActionMenu/ActionMenuButton';
import AddFeedAttemptModal from '../Modal/AddFeedAttemptModal';
import EditFeedAttemptModal from '../Modal/EditFeedAttemptModal';
import EditFoodItemModal from '../Modal/EditFoodItemModal';

dayjs.extend(advancedFormat);

const FoodItemRow = ({
  foodItem,
  category,
  style,
  onClick,
  disabled,
  loading,
  feedAttempts,
  odd,
}) => {
  const theme = useTheme();

  const styles = {
    container: {
      ...style,
      flex: 1,
      position: 'relative',
      minWidth: '350px',
      fontWeight: '400',
      // margin: '0 -20px',
      margin: '0',
      // TODO hover colour
      backgroundColor: odd ? `${category?.color}11` : '#F6F6F6', // Odd rows have slight colour to them

      ...style?.container,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      // boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '0',
      borderRadius: '8px',
      minHeight: '84px',
      alignItems: 'center',
      // overflow: "hidden",
      ...style?.row,
    },
    rowSection: {
      display: 'flex',
      gap: '24px',
      alignItems: 'center',
      // justifyContent: 'space-between',
    },
    imageContainer: {
      ...theme.components.clickable,
      position: 'relative',
      // width: "186px",
      width: '72px',
      height: '72px',
      overflow: 'hidden',
      backgroundColor: 'transparent',
      backgroundImage: `url('${foodItem?.image?.url}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '8px',
      ...style?.imageContainer,
    },
    image: {
      justifyContent: 'center',
      // width: "186px",
      // maxHeight: "198px",
      width: '100%',
      minWidth: '100%',
      minHeight: '198px',
      objectFit: 'fill',
      backgroundColor: 'black',
      objectPosition: '50% 50%',
      WebkitUserDrag: 'none',
      userSelect: 'none',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      ...style?.image,
    },
    details: {
      position: 'relative',
      ...style?.details,
    },
    name: {
      ...theme.typography.h2,
      ...style?.name,
    },
    feedAttempts: {
      display: 'flex',
      ...style?.feedAttempts,
    },
    foodName: {
      ...theme.typography.h4,
      flex: 1,
      ...theme.components.clickable,
      ...style?.foodName,
      color: '#303030',
    },
    rating: {
      ...style?.rating,
    },
    description: {
      ...style?.description,
    },
  };

  const feedAttemptsForItem = useMemo(() => {
    return sortObjectList(
      feedAttempts?.data?.filter((attempt) => attempt?.food_item_id === foodItem?.id),
      'attempted_at',
      true,
    );
  }, [feedAttempts?.data]);

  const [showAddFeedAttemptModal, setShowAddFeedAttemptModal] = useState(false);
  const [showEditFeedAttemptModal, setShowEditFeedAttemptModal] = useState(false);
  const [showEditFoodModal, setShowEditFoodModal] = useState(false);

  const handleClick = (event, feed = null) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    if (!isVoid(feed)) {
      setShowEditFeedAttemptModal(feed);
    } else {
      setShowAddFeedAttemptModal(true);
    }
  };

  const getFeedLabel = (feed) => {
    if (isVoid(feed)) {
      return <AddIcon />;
    }

    if (feed?.had_reaction) {
      return <ExclamationIcon />;
    }

    if (feed?.was_successful) {
      return <TickIcon />;
    }

    return <CrossIcon />;
  };

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={{ ...styles.rowSection }}>
          <div style={{ ...styles.rowSection, width: '250px' }}>
            <div style={styles.imageContainer} />
            <div style={styles.foodName}>{foodItem?.name}</div>
          </div>

          <div style={styles.rowSection}>
            <div style={styles.feedAttempts}>
              {/*TODO make component*/}
              <div style={{ display: 'flex', gap: '4px' }}>
                {/*// TODO turn into component with completion as a prop */}
                {range(5).map((attemptIndex) => {
                  const feed = feedAttemptsForItem?.[attemptIndex];

                  // TODO maybe show the 5 most recent rather than least recent?
                  return (
                    <div
                      key={`${feed?.id}-${attemptIndex}`}
                      onClick={(event) => handleClick(event, feed)}
                      style={{
                        width: '36px',
                        height: '36px',
                        border: `1px solid ${category.color}`,
                        backgroundColor: !isVoid(feed) ? category.color : 'transparent',
                        color: !isVoid(feed) ? 'white' : category.color,
                        borderRadius: '64px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: '400',
                        cursor: 'pointer',
                      }}
                    >
                      {getFeedLabel(feed)}
                    </div>
                  );
                })}

                {/*TODO need special completed state!*/}
                {feedAttemptsForItem.length >= 5 && (
                  <div
                    onClick={(event) => handleClick(event)}
                    style={{
                      width: '36px',
                      height: '36px',
                      border: `1px solid ${category.color}`,
                      // backgroundColor: 'white',
                      color: category.color,
                      // color: '#303030',
                      borderRadius: '64px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    {getFeedLabel(null)}
                  </div>
                )}
              </div>
            </div>

            {/*<OutlineButton*/}
            {/*  variant="square"*/}
            {/*  onClick={() => setShowAddFeedAttemptModal(true)}*/}
            {/*  style={{*/}
            {/*    container: {*/}
            {/*      border: `1px solid ${category.color}`,*/}
            {/*      color: 'white',*/}
            {/*      backgroundColor: category.color,*/}
            {/*    },*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div style={{ display: 'flex', gap: '4px' }}>*/}
            {/*    <AddIcon />*/}
            {/*    <div>Add attempt</div>*/}
            {/*  </div>*/}
            {/*</OutlineButton>*/}
          </div>
        </div>

        <div
          style={{
            ...styles.rowSection,
            ...{
              gap: '12px',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              textAlign: 'right',
              height: 'fit-content',
            },
          }}
        >
          <div style={styles.rowText}>
            Tried {feedAttemptsForItem?.length} {pluralise('time', feedAttemptsForItem?.length)}
          </div>

          {feedAttemptsForItem?.length > 0 && (
            <div style={styles.rowText}>
              Last tried {dayjs(feedAttemptsForItem[0].attempted_at).format('Do MMMM')}
            </div>
          )}
        </div>
        <div style={{ width: '20px' }}>
          <ActionMenuButton
            items={[
              {
                label: 'Edit food',
                onClick: () => {
                  setShowEditFoodModal(true);
                },
              },
            ]}
            // isOpen={showListMenu}
            // onClose={() => setShowListMenu(false)}
            // onSelect={addToList}
            style={{ textAlign: 'left' }}
            // items={foodLists?.data?.map((foodList) => ({
            //   label: foodList?.name,
            //   value: foodList?.id,
            // }))}
          />
        </div>
      </div>

      {/*<div style={{ position: 'absolute', textAlign: 'left', top: '50px', right: '50px' }}>*/}
      {/*  <ActionMenuButton*/}
      {/*    items={[*/}
      {/*      {*/}
      {/*        label: 'Edit food',*/}
      {/*        onClick: () => {*/}
      {/*          setShowEditFoodModal(true);*/}
      {/*        },*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*    // isOpen={showListMenu}*/}
      {/*    // onClose={() => setShowListMenu(false)}*/}
      {/*    // onSelect={addToList}*/}
      {/*    style={{ textAlign: 'left' }}*/}
      {/*    // items={foodLists?.data?.map((foodList) => ({*/}
      {/*    //   label: foodList?.name,*/}
      {/*    //   value: foodList?.id,*/}
      {/*    // }))}*/}
      {/*  />*/}
      {/*</div>*/}

      <EditFoodItemModal
        onClose={() => setShowEditFoodModal(false)}
        isOpen={showEditFoodModal}
        foodItem={foodItem}
      />

      <AddFeedAttemptModal
        onClose={() => setShowAddFeedAttemptModal(false)}
        isOpen={showAddFeedAttemptModal}
        foodId={foodItem.id}
        foodItem={foodItem}
      />

      <EditFeedAttemptModal
        onClose={() => setShowEditFeedAttemptModal(false)}
        isOpen={!!showEditFeedAttemptModal}
        feedAttempt={showEditFeedAttemptModal}
      />
    </div>
  );
};

FoodItemRow.defaultProps = {
  odd: false,
};

FoodItemRow.propTypes = {};

const mapStateToProps = (state) => ({
  feedAttempts: state.feedAttempts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FoodItemRow);
