export const COLORS = {
  gray: '#333333',
  gray3: '#B2B2B2',
  accentBlue: '#2626CD',
};

export const FONT_WEIGHTS = {
  regular: '400',
  medium: '500',
  bold: '600',
};

export type ThemeType = {
  brandColor: string;
  backgroundBlue: string;
  almostWhite: string;
  interactiveGray: string;
  typography: object;
  components: object;
} & Jss.Theme;

export const theme: ThemeType = {
  brandColor: '#04AA6D',
  backgroundBlue: '#E3E5EE',
  almostWhite: '#FCFCFF',
  // almostWhite: '#F6F6F6',
  interactiveGray: '#e8e8e8',
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: 1.5,
    },
    h4: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: 1.3,
    },
    small2: {
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '16px',
    },
    normal: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#333333',
    },
    light: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.3,
      color: '#B2B2B2',
    },
    featured: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '22px',
    },
    strong: {
      fontWeight: '600',
      fontSize: '14px',
    },
  },
  components: {
    clickable: {
      cursor: 'pointer',
      userSelect: 'none',
    },
    blueTextButton: {
      container: {
        padding: 0,
        fontSize: '14px',
        fontWeight: 400,
        // color: COLORS.accentBlue,
      },
    },
    card: {
      borderRadius: '4px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    },
    cardShadow: {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    },
  },
};

export const lightTheme = {
  ...theme,
  color: 'black',
};

export const darkTheme = {
  ...theme,
  color: 'white',
  almostWhite: '#AAAAAA',
};
