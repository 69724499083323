import React from 'react';

// TODO cleanup all .js component files
export const TextButton = ({ text, type, style, onClick, disabled, loading, children }) => {
  const styles = {
    container: {
      ...style,
      cursor: 'pointer',
      userSelect: 'none',
      transitionDuration: '0.1s',
      padding: '16px',
      fontWeight: 600,
      ...style?.container,
    },
    button: {
      ...style?.button,
    },
    disabled: {
      cursor: 'default',
    },
    text: {
      ...style?.text,
    },
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    onClick(event);
  };

  return (
    <div className="text-button-container" style={styles.container} onClick={handleClick}>
      <div style={styles.button}>
        <div style={styles.text}>
          {text} {children}
        </div>
      </div>
    </div>
  );
};
