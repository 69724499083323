import React from 'react';

export const TextLink = ({
  text,
  type,
  style,
  onClick = () => {},
  disabled = false,
  loading = false,
  children,
  ...other
}) => {
  const styles = {
    container: {
      ...style,
      cursor: 'pointer',
      userSelect: 'none',
      transitionDuration: '0.1s',
      fontWeight: 600,
      ...style?.container,
    },
    button: {
      ...style?.button,
    },
    text: {
      color: '#2626CD',
      ...style?.text,
    },
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    onClick(event);
  };

  return (
    <div className="text-link-container" style={styles.container}>
      <div style={styles.button} onClick={handleClick}>
        <div style={styles.text}>
          {text} {children}
        </div>
      </div>
    </div>
  );
};
