import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createFoodItemAction } from '../../data/actions/foodItemsAction';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { TextField } from '../TextField/TextField';
import { Modal } from './Modal';

const AddFoodModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  createFoodItem,
  foodItems,
  categoryId,
}) => {
  const [name, setName] = useState(null);
  // const [category, setCategory] = useState(null);
  const [image, setImage] = useState(null);

  const [nameError, setNameError] = useState('');
  // const [categoryError, setCategoryError] = useState('');
  const [imageError, setImageError] = useState('');

  const [posting, setPosting] = useState(false);

  // When opening, reset the state
  useEffect(() => {
    if (isOpen) {
      setName(null);
      setImage(null);
    }
  }, [isOpen]);

  const validate = () => {
    // TODO validation fields
    let valid = true;

    if (!name) {
      setNameError('You must set a name for the food!');
      valid = false;
    }

    // if (!category) {
    //   setNameError('You must set a category');
    //   valid = false;
    // }

    // if (!image) {
    //   setImageError('You must upload an image');
    //   valid = false;
    // }

    const imageUploadLimit = 100 * 1000 * 1000; // 100MB TODO set this in env
    if (image && !image?.size >= imageUploadLimit) {
      setImageError('File size must be less than 100MB');
      valid = false;
    }

    if (!valid) {
      console.log('form is not valid');
    }

    return valid;
  };

  const handleSubmit = () => {
    const valid = validate();

    if (!valid) {
      return;
    }

    createFoodItem({
      name: name,
      // eslint-disable-next-line camelcase
      food_category_id: categoryId,
      image,
    });

    setPosting(true);
  };

  // Close the modal when finished posting
  useEffect(() => {
    // TODO need a better way to have a callback for this specific feed attempt being posted
    if (posting && !foodItems?.posting && foodItems?.success) {
      setPosting(false);
      closeModal();
    }
  }, [foodItems?.posting, foodItems?.success]);

  const isDisabled = () => foodItems?.posting;

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      setName(null);
      setNameError(null);
      setImage(null);
      setImageError('');
      setPosting(false);
    });
    onClose();
  };

  // TODO show loading spinner while it is being created

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Add new food"
      submitText="Add new food"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={foodItems?.posting}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextField label={'Name'} value={name} onChange={setName} autofocus />
        <ImageUpload
          value={image}
          onChange={setImage}
          label="Image"
          style={{ maxWidth: '400px' }}
          error={imageError}
        />
        {/*<TextArea*/}
        {/*  label="Comments"*/}
        {/*  placeholder="Type your comments here..."*/}
        {/*  value={comment}*/}
        {/*  onChange={setComment}*/}
        {/*  error={commentError}*/}
        {/*  autofocus*/}
        {/*/>*/}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  foodItems: state.foodItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFoodItem: createFoodItemAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddFoodModal);
