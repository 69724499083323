import { createFoodListFoodItem, fetchFoodListFoodItems } from '../apiClient';
import { beginAction, handleFailure, handleResponse, showMessageOnSuccess } from './actions';
import * as type from './types';

export const fetchFoodListFoodItemsAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_FOOD_LIST_FOOD_ITEMS_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchFoodListFoodItems(includes, filters);

        if (response.errors.length > 0) {
          console.log('FETCH_FOOD_LIST_FOOD_ITEMS_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_FOOD_LIST_FOOD_ITEMS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_FOOD_LIST_FOOD_ITEMS_SUCCESS', response);
          dispatch({
            type: type.FETCH_FOOD_LIST_FOOD_ITEMS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_FOOD_LIST_FOOD_ITEMS_FAILURE', error);
        dispatch({
          type: type.FETCH_FOOD_LIST_FOOD_ITEMS_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

export const createFoodListFoodItemAction = (data) => (dispatch) => {
  beginAction('CREATE_FOOD_LIST_FOOD_ITEM', dispatch);

  (async () => {
    try {
      const response = await createFoodListFoodItem(data);
      handleResponse(response, 'CREATE_FOOD_LIST_FOOD_ITEM', dispatch);
      showMessageOnSuccess(
        response,
        'Game has been added to list',
        'CREATE_FOOD_LIST_FOOD_ITEM',
        dispatch,
      );
    } catch (error) {
      handleFailure(error, 'CREATE_FOOD_LIST_FOOD_ITEM', dispatch);
    }
  })();
};
