import { fetchSelf, fetchUser, fetchUsers } from '../apiClient';
import { beginAction, handleFailure, handleResponse } from './actions';
import * as type from './types';

export const setUserAction = (user) => (dispatch) => {
  // dispatch({
  //   type: type.FETCH_USER_SUCCESS,
  //   payload: user,
  // });
};

export const fetchUserAction = (userId, includes) => (dispatch) => {
  dispatch({
    type: type.FETCH_USER_PENDING,
  });

  const getData = async () => {
    let response = null;

    try {
      response = await fetchUser(userId);

      if (response.errors.length > 0) {
        console.log('FETCH_USER_FAILURE', response.errors);
        dispatch({
          type: type.FETCH_USER_FAILURE,
          payload: response.errors,
        });
      } else {
        console.log('FETCH_USER_SUCCESS', response);
        dispatch({
          type: type.FETCH_USER_SUCCESS,
          payload: response?.data?.data,
        });
      }
    } catch (error) {
      console.log('FETCH_USER_FAILURE', error);
      dispatch({
        type: type.FETCH_USER_FAILURE,
        payload: [error.message],
      });
    }
  };

  getData();
};

export const fetchUsersAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_USERS_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchUsers(includes, filters);

        if (response.errors.length > 0) {
          console.log('FETCH_USERS_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_GAME_USERS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_USERS_SUCCESS', response);
          dispatch({
            type: type.FETCH_USERS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_USERS_FAILURE', error);
        dispatch({
          type: type.FETCH_USERS_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

export const fetchSelfAction = () => (dispatch) => {
  dispatch({
    type: type.FETCH_SELF_PENDING,
  });

  const getData = async () => {
    let response = null;

    try {
      response = await fetchSelf();

      if (response.errors.length > 0) {
        console.log('FETCH_SELF_FAILURE', response.errors);
        dispatch({
          type: type.FETCH_SELF_FAILURE,
          payload: response.errors,
        });
      } else {
        console.log('FETCH_SELF_SUCCESS', response);
        dispatch({
          type: type.FETCH_SELF_SUCCESS,
          payload: response?.data?.data,
        });
      }
    } catch (error) {
      console.log('FETCH_SELF_FAILURE', error);
      dispatch({
        type: type.FETCH_SELF_FAILURE,
        payload: [error.message],
      });
    }
  };

  getData();
};

export const fetchUserProfileAction = (userId) => (dispatch) => {
  beginAction('FETCH_USER_PROFILE', dispatch);

  (async () => {
    try {
      Promise.all([fetchUser(userId)]).then((results) => {
        console.log('results', results);
        handleResponse(results[0], 'FETCH_USER', dispatch);
      });
    } catch (error) {
      handleFailure(error, 'FETCH_USER_PROFILE', dispatch);
    }
  })();
};
