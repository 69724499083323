import { mergeArrays, replaceItem } from '../../helpers/helpers';
import * as type from '../actions/types';

const initialState = {
  errors: [],
  fetching: false,
  posting: false,
  success: false,
  shouldFetch: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_FOOD_CATEGORIES_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        shouldFetch: false,
        success: false,
        errors: [],
      };
    case type.FETCH_FOOD_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        shouldFetch: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, 'id', true),
      };
    case type.FETCH_FOOD_CATEGORIES_FAILURE:
      return {
        ...state,
        fetching: false,
        shouldFetch: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_FOOD_CATEGORY_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.CREATE_FOOD_CATEGORY_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_FOOD_CATEGORY_SUCCESS:
      const newFoodCategory = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, newFoodCategory, 'id', true),
      };
    case type.UPDATE_FOOD_CATEGORY_SUCCESS:
      const updatedFoodCategory = action?.payload;

      console.log('aaa', action?.payload);

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, updatedFoodCategory, 'id', true),
      };
    case type.UPDATE_FOOD_CATEGORY_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.UPDATE_FOOD_CATEGORY_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.BATCH_UPDATE_FOOD_CATEGORIES_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.BATCH_UPDATE_FOOD_CATEGORIES_SUCCESS:
      return {
        ...state,
        shouldFetch: true,
        posting: false,
        success: true,
        errors: [],
      };
    case type.BATCH_UPDATE_FOOD_CATEGORIES_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
