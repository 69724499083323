import { flattenToProperty } from '../../helpers/helpers';
import { createBaby, deleteBaby, fetchBabies, updateBaby } from '../apiClient';
import * as type from './types';

export const fetchBabiesAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_BABIES_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchBabies(includes, filters);

        if (response.errors.length > 0) {
          console.log('FETCH_BABIES_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_BABIES_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_BABIES_SUCCESS', response);
          dispatch({
            type: type.FETCH_BABIES_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_BABIES_FAILURE', error);
        dispatch({
          type: type.FETCH_BABIES_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

/**
 * Update many game lists in one go
 *
 * NewBabies - the updated list including new indexes
 * OldBabies - the old values before updating. Items from this not in the new list will be deleted
 */
export const bulkUpdateBabiesAction = (newBabies, oldBabies) => (dispatch) => {
  dispatch({
    type: type.BATCH_UPDATE_BABIES_PENDING,
  });

  (async () => {
    const response = null;

    try {
      console.log('new game lists', newBabies);
      console.log('old game lists', oldBabies);

      const oldBabyIds = flattenToProperty(oldBabies, 'id');
      const newBabyIds = flattenToProperty(newBabies, 'id');

      const babiesToCreate = newBabies?.filter((newBaby) => !oldBabyIds?.includes(newBaby?.id));

      const babiesToUpdate = newBabies?.filter((newBaby) => oldBabyIds?.includes(newBaby?.id));

      const babiesToDelete = oldBabies?.filter((oldBaby) => !newBabyIds?.includes(oldBaby?.id));

      console.log('create', babiesToCreate);
      console.log('update', babiesToUpdate);
      console.log('delete', babiesToDelete);

      const createResponse = await Promise.all(
        babiesToCreate?.map((newBaby) =>
          createBaby({
            index: newBaby?.index,
            name: newBaby?.value,
          }),
        ),
      );

      const createErrors = createResponse?.filter((response) => response?.errors?.length > 0);
      if (createErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_BABIES_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      const updateResponse = await Promise.all(
        babiesToUpdate?.map((baby) =>
          updateBaby(
            {
              index: baby?.index,
              name: baby?.value,
            },
            baby?.id,
          ),
        ),
      );

      const updateErrors = updateResponse?.filter((response) => response?.errors?.length > 0);
      if (updateErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_BABIES_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      const deleteResponse = await Promise.all(babiesToDelete?.map((baby) => deleteBaby(baby?.id)));

      const deleteErrors = deleteResponse?.filter((response) => response?.errors?.length > 0);
      if (deleteErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_BABIES_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      // If we made it here, it worked

      // console.log("BATCH_UPDATE_BABIES_SUCCESS", createResponse, updateResponse, deleteResponse);

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: 'Game lists successfully updated',
          severity: 'success',
          id: `BATCH_UPDATE_BABIES_SUCCESS_${new Date().getTime()}`,
        },
      });

      dispatch({
        type: type.BATCH_UPDATE_BABIES_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.log('BATCH_UPDATE_BABIES_FAILURE', error);
      dispatch({
        type: type.BATCH_UPDATE_BABIES_FAILURE,
        payload: [error.message],
      });
    }
  })();
};
