import React, { useEffect } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FoodList from '../components/FoodList/FoodList';
import { fetchBabiesAction } from '../data/actions/babiesAction';
import { fetchFeedAttemptsAction } from '../data/actions/feedAttemptsAction';
import { fetchFoodCategoriesAction } from '../data/actions/foodCategoriesAction';
import { fetchFoodItemsAction } from '../data/actions/foodItemsAction';
import { fetchFoodListsAction } from '../data/actions/foodListsAction';

const Home = ({
  fetchFoodItems,
  fetchFoodLists,
  foodItems,
  foodCategories,
  fetchFeedAttempts,
  fetchFoodCategories,
  fetchBabies,
}) => {
  const theme = useTheme();

  // Set the page name TODO move this to route
  useEffect(() => {
    document.title = 'Baby Food';
  }, []);

  // Fetch all data for the app
  useEffect(() => {
    console.log('fetching all data');

    fetchFoodItems(['image']);
    fetchFoodLists(['categories', 'foodItems']);
    fetchFoodCategories();
    fetchBabies();
    fetchFeedAttempts(['image']);
  }, []);

  // // Sort the food into their categories
  // // TODO should be food items for this list specifically
  // const foodItemsByCategory = useMemo(() => {
  //   const foodByCategory = groupByProperty(foodItems?.data, 'food_category_id');
  //   console.log('food by category', foodByCategory);
  //
  //   return foodByCategory;
  // }, [foodItems?.data, foodCategories?.data]);

  return (
    <div
      className="dashboard-container"
      style={{ padding: '24px', paddingBottom: '500px', backgroundColor: theme.almostWhite }}
    >
      <FoodList heading="First Foods" loading={foodItems?.fetching || foodCategories?.fetching} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  foodItems: state.foodItems,
  foodCategories: state.categories,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFoodItems: fetchFoodItemsAction,
      fetchFoodLists: fetchFoodListsAction,
      fetchFeedAttempts: fetchFeedAttemptsAction,
      fetchFoodCategories: fetchFoodCategoriesAction,
      fetchBabies: fetchBabiesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
