import React from 'react';

import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as SortIcon } from '../../assets/sort_icon.svg';
import { sortOptions } from '../../data/lists';
import { OutlineButton } from '../Button/OutlineButton';
import { Dropdown } from '../Dropdown/Dropdown';

export const SortBar = ({
  style,
  setShowAddFoodModal,
  setShowAddCategoryModal,
  onChange = () => {},
  sortMode,
}) => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      ...style?.container,
    },
    searchBar: {
      ...style?.searchBar,
    },
    dropdown: {
      maxWidth: '270px',
      ...style?.dropdown,
    },
    icon: {
      ...style?.icon,
    },
    text: {
      ...style?.text,
    },
  };

  return (
    <div style={styles.container}>
      <Dropdown
        style={styles.dropdown}
        prefix={<SortIcon />}
        items={sortOptions()}
        onSelect={onChange}
        value={sortMode}
        allowDeselect={false}
      />
      <div style={{ display: 'flex', gap: '8px' }}>
        {/*<OutlineButton variant="square" onClick={() => setShowAddFoodModal(true)}>*/}
        {/*  <div style={{ display: 'flex', gap: '4px' }}>*/}
        {/*    <AddIcon style={{ color: '#333333' }} />*/}
        {/*    <div>Add new food</div>*/}
        {/*  </div>*/}
        {/*</OutlineButton>*/}

        <OutlineButton variant="square" onClick={() => setShowAddCategoryModal(true)}>
          <div style={{ display: 'flex', gap: '4px' }}>
            <AddIcon style={{ color: '#333333' }} />
            <div>Add new category</div>
          </div>
        </OutlineButton>
      </div>
    </div>
  );
};
