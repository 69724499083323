export const statuses = [
  { label: 'Added to list', value: 'added_to_list' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Done for now', value: 'done_for_now' },
  { label: 'Done playing', value: 'done_playing' },
  { label: 'Completed', value: 'completed' },
  { label: '100% completed', value: 'fully_completed' },
  { label: 'On hold', value: 'on_hold' },
  { label: 'Abandoned', value: 'abandoned' },
  // { label: "Replaying", value: "replaying" },
  { label: 'Not interested', value: 'not_interested' },
];

export const ratings = [
  { label: 'Must play', value: 'must_play' },
  { label: 'Recommended', value: 'recommended' },
  { label: 'Slightly recommended', value: 'slightly_recommended' },
  { label: 'No opinion', value: 'no_opinion' },
  { label: 'Not recommended', value: 'not_recommended' },
  { label: 'Do not play', value: 'do_not_play' },
];

export const getDefaultGameStatusValues = () => [
  { id: 'added_to_list', label: 'Added to list', value: false },
  { id: 'in_progress', label: 'In progress', value: false },
  { id: 'done_for_now', label: 'Done for now', value: false },
  { id: 'done_playing', label: 'Done playing', value: false },
  { id: 'completed', label: 'Completed', value: false },
  { id: 'fully_completed', label: '100% completed', value: false },
  { id: 'on_hold', label: 'On hold', value: false },
  { id: 'abandoned', label: 'Abandoned', value: false },
  { id: 'not_interested', label: 'Not interested', value: false },
  { id: 'no_status', label: 'No status set', value: false },
];

export const getDefaultRatingValues = () => [
  { id: 'must_play', label: 'Must play', value: false },
  { id: 'recommended', label: 'Recommended', value: false },
  { id: 'slightly_recommended', label: 'Slightly recommended', value: false },
  { id: 'no_opinion', label: 'No opinion', value: false },
  { id: 'not_recommended', label: 'Not recommended', value: false },
  { id: 'do_not_play', label: 'Do not play', value: false },
];

export const sortOptions = () => [
  { label: 'Default', value: 'default' },
  { label: 'Completion', value: 'completion' },
  { label: 'Newest first', value: 'newest' },
  { label: 'Oldest first', value: 'oldest' },
  { label: 'Recently fed first', value: 'recent' },
  { label: 'Recently fed last', value: 'not_recent' },
  { label: 'Alphabetical', value: 'alphabetical' },
];
