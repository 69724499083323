import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActionMenuButton } from '../../ActionMenu/ActionMenuButton';
import { TextButton } from '../../Button/TextButton';
import FoodItemRow from '../../FoodItemRow/FoodItemRow';
import AddFoodModal from '../../Modal/AddFoodModal';
import EditFoodCategoryModal from '../../Modal/EditFoodCategoryModal';

const FoodCategory = ({ foodCategory, style, loading, foodItems }) => {
  const theme = useTheme();

  const styles = {
    container: {
      ...style,
      // marginTop: "24px",
      display: 'flex',
      gap: '16px',
      width: '100%',
      ...style?.container,
    },
    heading: {
      color: 'white',
      backgroundColor: foodCategory.color,
      borderRadius: '16px 16px 0 0',
      padding: '8px 16px',
      userSelect: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      // fontSize: '20px',
      ...style?.heading,
    },
    filters: {
      ...style?.filters,
    },
    list: {
      flex: 1,
      ...style?.list,
    },
    listWrapper: {
      // marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      color: foodCategory.color,
      // alignItems: 'flex-start',
      // flexBasis: "50%",
      // flexWrap: 'wrap',
      // flex: 1,
      gap: 0,
      ...style?.listWrapper,
    },
  };

  const [showAddFoodModal, setShowAddFoodModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);

  // Filters
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div style={styles.container}>
      <div style={styles.list}>
        <div
          style={{ ...theme.typography.h3, ...styles.heading }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {foodCategory.name}
          <div>
            <ActionMenuButton
              items={[
                {
                  label: 'Edit category',
                  onClick: () => {
                    setShowEditCategoryModal(true);
                  },
                },
                {
                  label: 'Add food',
                  onClick: () => {
                    setShowAddFoodModal(true);
                  },
                },
                { label: 'Delete category' },
              ]}
            />
          </div>
        </div>
        {loading ? (
          <div style={{ marginTop: '16px' }}>Loading</div>
        ) : foodItems?.length === 0 ? (
          <div
            style={{
              textAlign: 'center',
              maxWidth: '500px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '40px',
            }}
          >
            <div style={{ ...theme.typography.featured, marginTop: '8px' }}>
              <TextButton
                onClick={() => setShowAddFoodModal(true)}
                style={{ container: { padding: 0, color: '#2626CD' } }}
              >
                Start adding food to {foodCategory.name}
              </TextButton>
            </div>
          </div>
        ) : (
          !collapsed && (
            <div style={styles.listWrapper}>
              {foodItems.map((foodItem, index) => (
                <>
                  {index !== 0 && (
                    <div
                      style={{ height: '1px', width: '100%', backgroundColor: foodCategory.color }}
                    />
                  )}

                  <FoodItemRow
                    key={`category-${foodCategory.id}-food-${foodItem?.id}`}
                    category={foodCategory}
                    foodItem={foodItem}
                    odd={index % 2}
                  />
                </>
              ))}
            </div>
          )
        )}
      </div>
      <AddFoodModal
        showCloseButton
        title="Add new food"
        isOpen={showAddFoodModal}
        categoryId={foodCategory.id}
        onClose={() => setShowAddFoodModal(false)}
      />
      <EditFoodCategoryModal
        showCloseButton
        title="Edit food category"
        isOpen={showEditCategoryModal}
        onClose={() => setShowEditCategoryModal(false)}
        foodCategory={foodCategory}
      />
    </div>
  );
};

FoodCategory.defaultProps = {
  foodItems: [],
};

FoodCategory.propTypes = {
  // activeUserAtTop: PropTypes.bool,
  // games: PropTypes.arrayOf(PropTypes.shape({})),
  // lists: PropTypes.arrayOf(PropTypes.shape({})),
  // activeUser: PropTypes.shape({}).isRequired,
  // foodCategoryFoodItems: PropTypes.shape({}).isRequired,
};

const mapStateToProps = () => ({
  // foodCategories: state.foodCategories,
  // foodItems: state.foodItems,
  // feedAttempts: state.feedAttempts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FoodCategory);
