import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { PrimaryButton } from '../components/Button/PrimaryButton';
import { TextLink } from '../components/Button/TextLink';
import { TextField } from '../components/TextField/TextField';
import { userSignupAction } from '../data/actions/signupAction';

const Signup = ({ userSignup, signup, login }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (errors) {
      setErrors([]);
    }
  }, [email, password, confirmPassword]);

  useEffect(() => {
    setErrors(signup?.errors);
  }, [signup.errors]);

  useEffect(() => {
    if (login.success && accessToken) {
      // Redirect the user since we have a token
      navigate('/home');
    }
  }, [login, accessToken]);

  const styles = {
    container: {},
    signup: {
      backgroundColor: '#FFFFFF',
      padding: '24px',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '450px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
    },
    errorMessage: {
      fontSize: '14px',
      color: 'red',
      marginBottom: '16px',
    },
  };

  const signupUser = async () => {
    let newErrors = [];

    if (!password || password.length < 8) {
      newErrors = [...newErrors, 'Password must be at least 8 characters.'];
    }

    if (password !== confirmPassword) {
      console.log('passwords do not match');
      newErrors = [...newErrors, 'Passwords do not match'];
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    console.log('submitting');
    setErrors([]);
    userSignup({ email, password });
  };

  return (
    <div>
      <div style={styles.signup}>
        <div style={{ marginBottom: '8px', ...theme.typography.h2 }}>Sign up</div>
        <div
          style={{
            marginBottom: '24px',
            fontSize: '12px',
            display: 'flex',
            gap: '4px',
          }}
        >
          <div>Already have an account?</div>
          <TextLink
            onClick={() => {
              setErrors([]);
              navigate('/login');
            }}
          >
            Log in
          </TextLink>
        </div>
        {errors.length > 0 && (
          <div style={styles.errorMessage}>
            <div style={{ fontWeight: 'bold' }}>There were errors creating the account:</div>
            {errors.map((error) => (
              <div>{error}</div>
            ))}
          </div>
        )}
        {/*<TextField*/}
        {/*  label="Username"*/}
        {/*  value={username}*/}
        {/*  onChange={setUsername}*/}
        {/*  placeholder="Username"*/}
        {/*  style={{ marginBottom: '16px' }}*/}
        {/*/>*/}
        <TextField
          label="Email"
          value={email}
          onChange={setEmail}
          placeholder="example@gmail.com"
          style={{ marginBottom: '16px' }}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
          placeholder="********"
          style={{ marginBottom: '16px' }}
        />
        <TextField
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={setConfirmPassword}
          placeholder="********"
          style={{ marginBottom: '24px' }}
        />
        <PrimaryButton
          text="Sign up"
          onClick={signupUser}
          loading={signup.loading}
          style={{ display: 'inline-block' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  signup: state.signup,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userSignup: userSignupAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
