import { mergeArrays, replaceItem } from '../../helpers/helpers';
import * as type from '../actions/types';

const initialState = {
  errors: [],
  fetching: false,
  posting: false,
  success: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_FEED_ATTEMPTS_PENDING:
      return {
        ...state,
        ...initialState,
        fetching: true,
        success: false,
        errors: [],
      };
    case type.FETCH_FEED_ATTEMPTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        errors: [],
        data: mergeArrays(state.data, action.payload?.data, 'id', true),
      };
    case type.FETCH_FEED_ATTEMPTS_FAILURE:
      return {
        ...state,
        fetching: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_FEED_ATTEMPT_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.CREATE_FEED_ATTEMPT_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.UPDATE_FEED_ATTEMPT_PENDING:
      return {
        ...state,
        posting: true,
        success: false,
        errors: [],
      };
    case type.UPDATE_FEED_ATTEMPT_FAILURE:
      return {
        ...state,
        posting: false,
        success: false,
        errors: action.payload,
      };
    case type.CREATE_FEED_ATTEMPT_SUCCESS:
      console.log('FeedAttempt was created', action?.payload, state);
      const newItem = action?.payload?.data;

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: replaceItem(state.data, newItem, 'id', true),
      };
    case type.UPDATE_FEED_ATTEMPT_SUCCESS:
      console.log('FeedAttempt was updated', action?.payload, state);
      const updatedItem = action?.payload?.data;

      // const updatedFeedAttempts = (state.data?.data || [])?.map((feedAttempt) => {
      //   if (updatedItem?.id === feedAttempt?.id) {
      //     return {
      //       ...feedAttempt,
      //       ...updatedItem,
      //     };
      //   }
      //
      //   return feedAttempt;
      // });

      const updatedFeedAttempts = replaceItem(state.data, updatedItem, 'id', true);

      return {
        ...state,
        posting: false,
        success: true,
        errors: [],
        data: updatedFeedAttempts,
      };
    default:
      return state;
  }
};
