// App only
export const SHOW_MANAGE_LISTS_MODAL = 'SHOW_MANAGE_LISTS_MODAL';
export const HIDE_MANAGE_LISTS_MODAL = 'HIDE_MANAGE_LISTS_MODAL';

// API RELATED
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const CLEAR_LOGIN_USER = 'CLEAR_LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const SIGNUP_USER_PENDING = 'SIGNUP_USER_PENDING';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const CLEAR_SIGNUP_USER = 'CLEAR_SIGNUP_USER';

export const FETCH_SELF_PENDING = 'FETCH_SELF_PENDING';
export const FETCH_SELF_SUCCESS = 'FETCH_SELF_SUCCESS';
export const FETCH_SELF_FAILURE = 'FETCH_SELF_FAILURE';

// Food items
export const FETCH_FOOD_ITEM_PENDING = 'FETCH_FOOD_ITEM_PENDING';
export const FETCH_FOOD_ITEM_SUCCESS = 'FETCH_FOOD_ITEM_SUCCESS';
export const FETCH_FOOD_ITEM_FAILURE = 'FETCH_FOOD_ITEM_FAILURE';

export const FETCH_FOOD_ITEMS_PENDING = 'FETCH_FOOD_ITEMS_PENDING';
export const FETCH_FOOD_ITEMS_SUCCESS = 'FETCH_FOOD_ITEMS_SUCCESS';
export const FETCH_FOOD_ITEMS_FAILURE = 'FETCH_FOOD_ITEMS_FAILURE';

export const CREATE_FOOD_ITEM_PENDING = 'CREATE_FOOD_ITEM_PENDING';
export const CREATE_FOOD_ITEM_SUCCESS = 'CREATE_FOOD_ITEM_SUCCESS';
export const CREATE_FOOD_ITEM_FAILURE = 'CREATE_FOOD_ITEM_FAILURE';

export const UPDATE_FOOD_ITEM_PENDING = 'UPDATE_FOOD_ITEM_PENDING';
export const UPDATE_FOOD_ITEM_SUCCESS = 'UPDATE_FOOD_ITEM_SUCCESS';
export const UPDATE_FOOD_ITEM_FAILURE = 'UPDATE_FOOD_ITEM_FAILURE';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';

// Food categories
export const FETCH_FOOD_CATEGORY_PENDING = 'FETCH_FOOD_CATEGORY_PENDING';
export const FETCH_FOOD_CATEGORY_SUCCESS = 'FETCH_FOOD_CATEGORY_SUCCESS';
export const FETCH_FOOD_CATEGORY_FAILURE = 'FETCH_FOOD_CATEGORY_FAILURE';

export const FETCH_FOOD_CATEGORIES_PENDING = 'FETCH_FOOD_CATEGORIES_PENDING';
export const FETCH_FOOD_CATEGORIES_SUCCESS = 'FETCH_FOOD_CATEGORIES_SUCCESS';
export const FETCH_FOOD_CATEGORIES_FAILURE = 'FETCH_FOOD_CATEGORIES_FAILURE';

export const CREATE_FOOD_CATEGORY_PENDING = 'CREATE_FOOD_CATEGORY_PENDING';
export const CREATE_FOOD_CATEGORY_SUCCESS = 'CREATE_FOOD_CATEGORY_SUCCESS';
export const CREATE_FOOD_CATEGORY_FAILURE = 'CREATE_FOOD_CATEGORY_FAILURE';

export const UPDATE_FOOD_CATEGORY_PENDING = 'UPDATE_FOOD_CATEGORY_PENDING';
export const UPDATE_FOOD_CATEGORY_SUCCESS = 'UPDATE_FOOD_CATEGORY_SUCCESS';
export const UPDATE_FOOD_CATEGORY_FAILURE = 'UPDATE_FOOD_CATEGORY_FAILURE';

export const BATCH_UPDATE_FOOD_CATEGORIES_PENDING = 'BATCH_UPDATE_FOOD_CATEGORIES_PENDING';
export const BATCH_UPDATE_FOOD_CATEGORIES_SUCCESS = 'BATCH_UPDATE_FOOD_CATEGORIES_SUCCESS';
export const BATCH_UPDATE_FOOD_CATEGORIES_FAILURE = 'BATCH_UPDATE_FOOD_CATEGORIES_FAILURE';

// Babies
export const FETCH_BABY_PENDING = 'FETCH_BABY_PENDING';
export const FETCH_BABY_SUCCESS = 'FETCH_BABY_SUCCESS';
export const FETCH_BABY_FAILURE = 'FETCH_BABY_FAILURE';

export const FETCH_BABIES_PENDING = 'FETCH_BABIES_PENDING';
export const FETCH_BABIES_SUCCESS = 'FETCH_BABIES_SUCCESS';
export const FETCH_BABIES_FAILURE = 'FETCH_BABIES_FAILURE';

export const CREATE_BABY_PENDING = 'CREATE_BABY_PENDING';
export const CREATE_BABY_SUCCESS = 'CREATE_BABY_SUCCESS';
export const CREATE_BABY_FAILURE = 'CREATE_BABY_FAILURE';

export const UPDATE_BABY_PENDING = 'UPDATE_BABY_PENDING';
export const UPDATE_BABY_SUCCESS = 'UPDATE_BABY_SUCCESS';
export const UPDATE_BABY_FAILURE = 'UPDATE_BABY_FAILURE';

export const BATCH_UPDATE_BABIES_PENDING = 'BATCH_UPDATE_BABIES_PENDING';
export const BATCH_UPDATE_BABIES_SUCCESS = 'BATCH_UPDATE_BABIES_SUCCESS';
export const BATCH_UPDATE_BABIES_FAILURE = 'BATCH_UPDATE_BABIES_FAILURE';

// FeedAttempts
export const FETCH_FEED_ATTEMPTS_PENDING = 'FETCH_FEED_ATTEMPTS_PENDING';
export const FETCH_FEED_ATTEMPTS_SUCCESS = 'FETCH_FEED_ATTEMPTS_SUCCESS';
export const FETCH_FEED_ATTEMPTS_FAILURE = 'FETCH_FEED_ATTEMPTS_FAILURE';

export const CREATE_FEED_ATTEMPT_PENDING = 'CREATE_FEED_ATTEMPT_PENDING';
export const CREATE_FEED_ATTEMPT_SUCCESS = 'CREATE_FEED_ATTEMPT_SUCCESS';
export const CREATE_FEED_ATTEMPT_FAILURE = 'CREATE_FEED_ATTEMPT_FAILURE';

export const UPDATE_FEED_ATTEMPT_PENDING = 'UPDATE_FEED_ATTEMPT_PENDING';
export const UPDATE_FEED_ATTEMPT_SUCCESS = 'UPDATE_FEED_ATTEMPT_SUCCESS';
export const UPDATE_FEED_ATTEMPT_FAILURE = 'UPDATE_FEED_ATTEMPT_FAILURE';

// Users
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

// Game Lists
export const FETCH_FOOD_LISTS_PENDING = 'FETCH_FOOD_LISTS_PENDING';
export const FETCH_FOOD_LISTS_SUCCESS = 'FETCH_FOOD_LISTS_SUCCESS';
export const FETCH_FOOD_LISTS_FAILURE = 'FETCH_FOOD_LISTS_FAILURE';

export const CREATE_FOOD_LIST_PENDING = 'CREATE_FOOD_LIST_PENDING';
export const CREATE_FOOD_LIST_SUCCESS = 'CREATE_FOOD_LIST_SUCCESS';
export const CREATE_FOOD_LIST_FAILURE = 'CREATE_FOOD_LIST_FAILURE';

export const UPDATE_FOOD_LIST_PENDING = 'UPDATE_FOOD_LIST_PENDING';
export const UPDATE_FOOD_LIST_SUCCESS = 'UPDATE_FOOD_LIST_SUCCESS';
export const UPDATE_FOOD_LIST_FAILURE = 'UPDATE_FOOD_LIST_FAILURE';

export const BATCH_UPDATE_FOOD_LISTS_PENDING = 'BATCH_UPDATE_FOOD_LISTS_PENDING';
export const BATCH_UPDATE_FOOD_LISTS_SUCCESS = 'BATCH_UPDATE_FOOD_LISTS_SUCCESS';
export const BATCH_UPDATE_FOOD_LISTS_FAILURE = 'BATCH_UPDATE_FOOD_LISTS_FAILURE';

// Game List Games
export const FETCH_FOOD_LIST_FOOD_ITEMS_PENDING = 'FETCH_FOOD_LIST_FOOD_ITEMS_PENDING';
export const FETCH_FOOD_LIST_FOOD_ITEMS_SUCCESS = 'FETCH_FOOD_LIST_FOOD_ITEMS_SUCCESS';
export const FETCH_FOOD_LIST_FOOD_ITEMS_FAILURE = 'FETCH_FOOD_LIST_FOOD_ITEMS_FAILURE';

export const CREATE_FOOD_LIST_FOOD_ITEM_PENDING = 'CREATE_FOOD_LIST_FOOD_ITEM_PENDING';
export const CREATE_FOOD_LIST_FOOD_ITEM_SUCCESS = 'CREATE_FOOD_LIST_FOOD_ITEM_SUCCESS';
export const CREATE_FOOD_LIST_FOOD_ITEM_FAILURE = 'CREATE_FOOD_LIST_FOOD_ITEM_FAILURE';

export const UPDATE_FOOD_LIST_FOOD_ITEM_PENDING = 'UPDATE_FOOD_LIST_FOOD_ITEM_PENDING';
export const UPDATE_FOOD_LIST_FOOD_ITEM_SUCCESS = 'UPDATE_FOOD_LIST_FOOD_ITEM_SUCCESS';
export const UPDATE_FOOD_LIST_FOOD_ITEM_FAILURE = 'UPDATE_FOOD_LIST_FOOD_ITEM_FAILURE';

// Other

export const FETCH_USER_PROFILE_PENDING = 'FETCH_USER_PROFILE_PENDING';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
