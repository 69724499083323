import { signup as apiSignup } from '../apiClient';
import * as type from './types';

export const userSignupAction =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({
      type: type.SIGNUP_USER_PENDING,
      payload: email,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await apiSignup({ email, password });

        console.log('response', response);

        if (response.errors.length > 0) {
          console.log('SIGNUP_USER_FAILURE', response.errors);
          dispatch({
            type: type.SIGNUP_USER_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('SIGNUP_USER_SUCCESS', response);
          dispatch({
            type: type.SIGNUP_USER_SUCCESS,
            payload: { email, token: response.data.token },
          });

          // Also login the user
          dispatch({
            type: type.LOGIN_USER_SUCCESS,
            payload: { email, token: response.data.token },
          });
        }
      } catch (error) {
        console.log('SIGNUP_USER_FAILURE', error);
        dispatch({
          type: type.SIGNUP_USER_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };
