import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-jss';

import { ReactComponent as ChevronDown } from '../../assets/icons/chevron_down.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron_up.svg';
import { detectClickOutside } from '../../helpers/helpers';
import { COLORS, theme } from '../../theme';

// TODO this is still a text field
export const Dropdown = ({
  style,
  label,
  disabled,
  items,
  prefix,
  loading,
  allowDeselect,
  placeholder,
  onSelect,
  value,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [sortedItems, setSortedItems] = useState([]);

  // Sort / filter the list TODO should probs be useRef
  useEffect(() => {
    let newItems = [...(items || [])];
    if (allowDeselect) {
      newItems = [{ label: '-', value: null }, ...newItems];
    }

    setSortedItems(newItems);
  }, [items, allowDeselect]);
  const ref = useRef(null);

  const handleSelect = (newValue) => {
    // Can't change if not editable
    if (disabled) {
      return;
    }

    onSelect(newValue);
    close();
  };

  const styles = {
    container: {
      ...style,
      position: 'relative',
      width: '100%',
      borderRadius: '4px',
      fontSize: '14px',
      ...style?.container,
    },
    label: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#333333',
      marginBottom: '8px',
      ...style?.label,
    },
    dropdown: {
      position: 'relative',
      borderRadius: '4px',
      minHeight: '42px',
      cursor: 'pointer',
      backgroundColor: '#FFFFFF',
      ...style?.dropdown,
    },
    dropdownClosed: {
      height: '40px',
      border: '1px solid #D4D4D4',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      ...style?.dropdownClosed,
    },
    dropdownOpen: {
      position: 'absolute',
      border: '1px solid #D4D4D4',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      width: 'calc(100% - 2px)', // because of the border
      minHeight: '80px',
      zIndex: '10',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      ...style?.dropdownClosed,
    },
    dropdownOpenTop: {
      height: '40px',
      display: 'flex',
      ...style?.dropdownClosed,
    },
    textContainer: {
      userSelect: 'none',
      whiteSpace: 'nowrap',
      padding: '8px 16px',
      fontSize: '14px',
      lineHeight: '24px',
      ...style?.text,
    },
    text: {
      ...style?.text,
    },
    placeholder: {
      color: COLORS.gray3,
      ...style?.placeholder,
    },
    icon: {
      width: '16px',
      height: '16px',
      marginLeft: 'auto',
      padding: '13px 16px',
      color: COLORS.gray,
      ...style?.icon,
    },
    itemList: {
      userSelect: 'none',
      whiteSpace: 'nowrap',
      maxHeight: '300px',
      overflowY: 'auto',
      ...style?.itemList,
    },
    item: {
      padding: '16px',
      ...theme.components.clickable,
      ...style?.item,
    },
  };

  const wrapperRef = useRef(null);
  detectClickOutside(wrapperRef, () => setOpen(false));

  const toggleOpen = () => {
    setOpen(!open);
  };

  const close = () => {
    setOpen(false);
  };

  const openDropdown = () => {
    setOpen(true);
  };

  const selectedItem = sortedItems?.find((item) => item?.value === value) || {};

  const getTextWithPrefix = (text) => {
    if (!prefix) {
      return text;
    }

    return (
      <div style={{ display: 'flex', gap: '8px' }}>
        {prefix}
        <div style={{ lineHeight: '24px' }}>{text}</div>
      </div>
    );
  };

  return (
    <div style={styles.container} onBlur={close}>
      {label && <div style={styles.label}>{label}</div>}
      <div
        style={styles.dropdown}
        onClick={openDropdown}
        tabIndex={0}
        onFocus={openDropdown} // This replaces onclick + works for keyboard
      >
        {open ? (
          <div style={styles.dropdownOpen} ref={wrapperRef}>
            <div style={styles.dropdownOpenTop}>
              <div style={styles.textContainer}>
                {value ? (
                  <div style={styles.text}>{getTextWithPrefix(selectedItem?.label)}</div>
                ) : (
                  <div style={styles.placeholder}>{getTextWithPrefix(placeholder)}</div>
                )}
              </div>
              <ChevronUp style={styles.icon} />
            </div>
            <div style={styles.itemList}>
              {sortedItems.map((sortedItem) => (
                <div
                  key={sortedItem?.value}
                  style={styles.item}
                  className="dropdown-item"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSelect(sortedItem?.value);
                  }}
                >
                  {sortedItem?.label}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div style={styles.dropdownClosed}>
            <div style={styles.textContainer}>
              {value ? (
                <div style={styles.text}>{getTextWithPrefix(selectedItem?.label)}</div>
              ) : (
                <div style={styles.placeholder}>{getTextWithPrefix(placeholder)}</div>
              )}
            </div>
            <ChevronDown style={styles.icon} />
          </div>
        )}
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  allowDeselect: true,
  placeholder: 'Select...',
  onSelect: () => {},
  value: null,
};
