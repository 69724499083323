import './index.css';

import { Theme } from '@radix-ui/themes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import store from './data/store';
import router from './router.js';
import { darkTheme, lightTheme } from './theme.ts';

// TODO support dark mode properly later
const isDarkMode = false;
// const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
const theme = isDarkMode ? darkTheme : lightTheme;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store()}>
      <Theme>
        <ThemeProvider theme={theme}>
          <div className="app">
            <RouterProvider router={router} />
          </div>
        </ThemeProvider>
      </Theme>
    </Provider>
  </React.StrictMode>,
);
