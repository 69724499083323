import {
  createFoodItem,
  fetchFoodItem,
  fetchFoodItems,
  updateFoodItem,
  uploadImage,
} from '../apiClient';
import { beginAction, handleFailure, handleResponse, showMessageOnSuccess } from './actions';
import * as type from './types';

export const fetchFoodItemsAction =
  (includes = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_FOOD_ITEMS_PENDING,
    });

    const getData = async () => {
      let response = null;

      try {
        response = await fetchFoodItems(includes);

        if (response.errors.length > 0) {
          console.log('FETCH_FOOD_ITEMS_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_FOOD_ITEMS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_FOOD_ITEMS_SUCCESS', response);
          dispatch({
            type: type.FETCH_FOOD_ITEMS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_FOOD_ITEMS_FAILURE', error);
        dispatch({
          type: type.FETCH_FOOD_ITEMS_FAILURE,
          payload: [error.message],
        });
      }
    };

    getData();
  };

export const fetchFoodItemAction =
  (foodItemId, includes = []) =>
  (dispatch) => {
    beginAction('FETCH_FOOD_ITEM', dispatch);

    (async () => {
      try {
        const response = await fetchFoodItem(foodItemId, includes);
        handleResponse(response, 'FETCH_FOOD_ITEM', dispatch);
      } catch (error) {
        handleFailure(error, 'FETCH_FOOD_ITEM', dispatch);
      }
    })();
  };

export const createFoodItemAction = (foodItemData) => (dispatch) => {
  console.log('preparing to create foodItem', foodItemData);

  dispatch({
    type: type.CREATE_FOOD_ITEM_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;
      let imageResponse = null;

      console.log('creating foodItem', foodItemData);

      // TODO Image stuff should be its own method somewhere

      // Upload image
      if (foodItemData?.image) {
        const imageData = {
          name: foodItemData?.image?.name,
          format: foodItemData?.image?.format,
          width: foodItemData?.image?.width,
          height: foodItemData?.image?.height,
          filesize: foodItemData?.image?.size,
          data: foodItemData?.image?.data,
          is_public: false,
        };
        imageResponse = await uploadImage(imageData);

        if (imageResponse.errors.length > 0) {
          dispatch({
            type: type.CREATE_FOOD_ITEM_FAILURE,
            payload: imageResponse.errors,
          });
          return;
        }
      }

      // Now create the foodItem
      response = await createFoodItem({
        name: foodItemData?.name,
        image_id: imageResponse?.data?.data?.id,
        food_category_id: foodItemData?.food_category_id,
        // store_page_url: foodItemData?.store_page_url,
        // release_date: foodItemData?.release_date,
      });

      if (response.errors.length > 0) {
        console.log('CREATE_FOOD_ITEM_FAILURE', response.errors);
        dispatch({
          type: type.CREATE_FOOD_ITEM_FAILURE,
          payload: response.errors,
        });
      } else {
        console.log('CREATE_FOOD_ITEM_SUCCESS', response);
        dispatch({
          type: type.CREATE_FOOD_ITEM_SUCCESS,
          payload: {
            ...response?.data,
            data: { ...response?.data?.data, image: imageResponse?.data },
          },
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: 'Food successfully created',
            severity: 'success',
            id: `CREATE_FOOD_ITEM_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log('CREATE_FOOD_ITEM_FAILURE', error);
      dispatch({
        type: type.CREATE_FOOD_ITEM_FAILURE,
        payload: [error.message],
      });
    }
  };

  postData();
};

export const updateFoodItemAction = (foodItemId, foodItemData) => (dispatch) => {
  console.log('preparing to update foodItem', foodItemData);

  beginAction('UPDATE_FOOD_ITEM', dispatch);

  (async () => {
    try {
      let response = null;
      let imageResponse = null;

      if (foodItemData?.image) {
        // TODO Image stuff should be its own method somewhere
        // Upload image
        const imageData = {
          name: foodItemData?.image?.name,
          format: foodItemData?.image?.format,
          width: foodItemData?.image?.width,
          height: foodItemData?.image?.height,
          filesize: foodItemData?.image?.size,
          data: foodItemData?.image?.data,
          is_public: true,
        };
        imageResponse = await uploadImage(imageData);

        if (imageResponse.errors.length > 0) {
          dispatch({
            type: type.UPDATE_FOOD_ITEM_FAILURE,
            payload: imageResponse.errors,
          });
          return;
        }
      }

      const newFoodItemData = {
        name: foodItemData?.name,
        year: foodItemData?.year,
        description: foodItemData?.description,
        image_id: foodItemData?.image_id,
        // store_page_url: foodItemData?.store_page_url,
        // release_date: foodItemData?.release_date,
      };

      if (imageResponse) {
        newFoodItemData.image_id = imageResponse?.data?.data?.id;
      }

      // Now update the foodItem
      response = await updateFoodItem(newFoodItemData, foodItemId);
      handleResponse(response, 'UPDATE_FOOD_ITEM', dispatch);
      showMessageOnSuccess(
        response,
        'Food successfully updated',
        type.UPDATE_FOOD_ITEM_SUCCESS,
        dispatch,
      );
    } catch (error) {
      handleFailure(error, 'UPDATE_FOOD_ITEM', dispatch);
    }
  })();
};
