import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

export const formatDate = (date, format = 'MMM D YYYY', invalidString = '') => {
  const newDate = dayjs(date).format(format);

  if (newDate === 'Invalid date') {
    return invalidString;
  }

  return newDate;
};

export const formatDateForUpload = (date) => formatDate(date, 'YYYY-MM-DD', null);

export const getDateFromString = (dateString, defaultToNow = true) => {
  if (!dateString && defaultToNow) {
    return new Date();
  }

  return new Date(dateString);
};

export function filterUnique(list) {
  if (!list) {
    return [];
  }

  return list.filter((value, index, self) => self.indexOf(value) === index);
}

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      const newPs = { ...ps };
      if (prev.current[k] !== v) {
        newPs[k] = [prev.current[k], v];
      }
      return newPs;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export const getLabelForValue = (value, items) =>
  items?.find((item) => item?.value === value)?.label;

// Detect if clicking outside a component
export const detectClickOutside = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }

    setTimeout(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }, 1);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export const itemsAsValueList = (items, valueProperty = 'id', labelProperty = 'name') => {
  if (!items || items?.length === 0) {
    return [];
  }

  return items?.map((item) => ({
    value: item?.[valueProperty],
    label: item?.[labelProperty],
  }));
};

export const isVoid = (item) => item === null || item === undefined;

export const sortValues = (a, b, descending = false) => {
  if (a === b) {
    return 0;
  }

  if (!isVoid(a) && isVoid(b)) {
    // console.log("found a null, a is bigger", a);
    return descending ? -1 : 1;
  }

  if (isVoid(a) && !isVoid(b)) {
    // console.log("found a null, b is bigger", b);
    return descending ? 1 : -1;
  }

  return a > b ? (descending ? -1 : 1) : descending ? 1 : -1;
};

// TODO make immutable
// TODO this is duplicated in utils/helpers. Should delete one
export const sortObjectList = (items, property = 'name', descending = false) =>
  items.slice(0)?.sort((a, b) => sortValues(a?.[property], b?.[property], descending));

// Plucks out specific keys from an object and returns only them in a new object
export const pluck = (object, keys) => {
  const newObject = {};

  if (!keys || keys?.length === 0) {
    return [];
  }

  console.log('keys', keys);

  keys?.forEach((key) => {
    if (Object.hasOwn(object, key)) {
      newObject[key] = object[key];
    }
  });

  return newObject;
};

/**
 * Flatten an array of objects into just the values of a specific key
 */
export const flatten = (array, key = 'id') => {
  if (!array?.length) {
    return [];
  }

  return array?.map((item) => item?.[key]);
};

export const filterByProperty = (array, value, property = 'user_id') => {
  if (!array || array?.length === 0 || value === undefined) {
    return [];
  }

  return array?.filter((item) => item?.[property] === value);
};

/**
 * Loop through an array of objects, replacing the
 * item that is searched for.
 *
 * Optionally add the new item if it isn't in the list already
 */
export const replaceItem = (array, item, property = 'id', addIfNotFound = false, merge = true) => {
  console.log('attempting to update', item, array);
  let found = false;
  let newArray = array.map((arrayItem) => {
    if (arrayItem?.[property] === item?.[property]) {
      found = true;

      // Prevents overriding modifications such as includes
      if (merge) {
        return { ...arrayItem, ...item };
      }
      return item;
    }

    return arrayItem;
  });

  if (addIfNotFound && !found) {
    newArray = [...array, item];
  }

  console.log('updated', newArray);

  return newArray;
};

export const mergeArrays = (oldArray, newArray, property) => {
  // Loop through old array, keeping any objects that aren't overridden
  const oldItemsToKeep = oldArray.filter(
    (oldItem) =>
      newArray.find((newItem) => newItem?.[property] === oldItem?.[property]) === undefined,
  );

  return [...newArray, ...oldItemsToKeep];
};

/**
 * Searches a list of objects and returns the first entry where the key matches
 * the given value
 */
export const findByKey = (array, value, key = 'id') => {
  if (!array || array?.length === 0 || value === undefined) {
    return null;
  }

  return array?.find((item) => item?.[key] === value);
};

export const pluralise = (string, number, pluralString) => {
  if (number === 1) {
    return string;
  }

  if (pluralString) {
    return pluralString;
  }

  return `${string}s`;
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// Reduce an array of objects down to the list of values of that property.
// E.g. a list of strings representing an id or name
export const flattenToProperty = (array, property = 'id') => {
  if (!array || array?.length === 0) {
    return null;
  }

  return array?.map((item) => item?.[property]);
};

/**
 * Convert a string into one that's good for searching.
 * Set to lower case, remove extra whitespace, etc.
 */
export const getStringForSearch = (string) => string?.toLowerCase()?.trim().replace(/\s/g, '');

export const stringInString = (stringToSearch, searchText) =>
  getStringForSearch(stringToSearch)?.includes(getStringForSearch(searchText));

export const createRandomString = (length) => Math.random().toString(36).substr(2, length);

/**
 * Move an item from one index to another.
 * Inserts BEFORE the destination. e.g. 0 will put at the start of the list
 */
export const move = (array, from, to) => {
  if (from === to) {
    return array;
  }

  const alteredArray = [...array];
  const itemToMove = alteredArray.splice(from, 1)[0];

  // console.log("MOVING", [...array], from, to);
  // console.log("item to move", itemToMove);
  // console.log("array without item", alteredArray);

  // Move to end if -1
  if (to === -1) {
    return [...alteredArray, itemToMove];
  }

  if (from < to) {
    // index of to has shifted down one
    alteredArray.splice(to - 1, 0, itemToMove);
  } else {
    alteredArray.splice(to, 0, itemToMove);
  }

  return alteredArray;
};
