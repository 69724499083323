import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Layout from './layouts/Layout.jsx';
import { LoginLayout } from './layouts/LoginLayout.tsx';
import ErrorScreen from './screens/ErrorScreen';
import Home from './screens/Home';
import Login from './screens/Login.jsx';
import Logout from './screens/Logout';
import Signup from './screens/Signup.jsx';

// TODO not found page
const router = createBrowserRouter([
  {
    path: '/',
    // element: <AppLayout />,
    errorElement: <ErrorScreen />,
    children: [
      {
        errorElement: <ErrorScreen />,
        children: [
          {
            index: true,
            element: (
              <Layout>
                <Home />
              </Layout>
            ),
          },
          {
            path: '/home',
            element: (
              <Layout>
                <Home />
              </Layout>
            ),
          },
          {
            path: '/login',
            element: (
              <LoginLayout>
                <Login />
              </LoginLayout>
            ),
          },
          {
            path: '/logout',
            element: (
              <LoginLayout>
                <Logout />
              </LoginLayout>
            ),
          },
          {
            path: '/signup',
            element: (
              <LoginLayout>
                <Signup />
              </LoginLayout>
            ),
          },
          // {
          //   path: '/user/:userId',
          //   element: <Profile />,
          // },
          // {
          //   path: '/game/:gameId',
          //   element: <GameProfile />,
          // },
        ],
      },
    ],
  },
]);

export default router;
