import React, { useEffect, useRef } from 'react';

import { TextField } from '../TextField/TextField';
export const ColorPicker = ({
  style,
  label,
  disabled,
  value,
  loading,
  placeholder,
  onChange,
  autofocus,
}) => {
  // const [startDate, setStartDate] = useState(new Date());

  const ref = useRef();
  const handleOnChange = (newValue) => {
    console.log('new value', newValue);

    // Can't change if not editable
    if (disabled) {
      return;
    }

    // const newValue = event.target.value;

    onChange(newValue);
  };

  const handleNativeChange = (event) => {
    return handleOnChange(event?.target?.value);
  };

  // Auto focus
  useEffect(() => {
    // The timeout is because inputs revealed on click (e.g. in modals) have
    // their inputs stolen by the click
    if (ref.current && autofocus) {
      setTimeout(() => {
        console.log('focusing');
        ref.current.focus();
      }, 50);
    }
  }, []);

  const styles = {
    container: {
      ...style,
      position: 'relative',
      width: '100%',
      borderRadius: '4px',
      ...style?.container,
    },
    label: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '19px',
      color: '#333333',
      marginBottom: '8px',
      ...style?.label,
    },
    inputs: {
      display: 'flex',
      position: 'relative',
      // gap: '4px',
      ...style?.inputs,
    },
    colorPreview: {
      width: '40px',
      height: '40px',
      position: 'absolute',
      // border: 'none',
      zIndex: 1,
      ...style?.colorPreview,
    },
    textInput: {
      ...style?.textInput,
      container: {
        maxWidth: '150px',
        borderRadius: '10px',
        // marginLeft: '-4px',
        borderLeft: '1px solid transparent',
        textTransform: 'uppercase',
        ...style?.textInput?.container,
      },
      text: {
        borderRadius: '0 4px 4px 0',
        paddingLeft: '52px',
        ...style?.textInput?.text,
      },
    },
  };

  return (
    <div style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}

      <div style={styles.inputs}>
        <input
          type="color"
          value={value ?? ''}
          onChange={(e) => handleOnChange(e?.target?.value)}
          style={styles.colorPreview}
        />

        <TextField
          value={value}
          onChange={handleOnChange}
          style={styles.textInput}
          placeholder={'#FFFFFF'}
        />
      </div>
    </div>
  );
};

ColorPicker.defaultProps = {
  loading: false,
  placeholder: 'Start typing...',
  onSelect: () => {},
  onBlur: () => {},
  autofocus: false,
  range: false,
};
