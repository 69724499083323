import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { detectClickOutside } from '../../helpers/helpers';

export const ActionMenu = ({ style, items, isOpen, onSelect, onClose, align }) => {
  const theme = useTheme();
  // TODO detect click outside of menu

  const onClick = (item) => {
    console.log('clicked', item);

    if (item?.onClick) {
      item?.onClick();
    }

    onSelect(item);
    onClose();
  };

  const wrapperRef = useRef(null);
  detectClickOutside(wrapperRef, () => onClose());

  const styles = {
    container: {
      ...style,
      position: 'relative',
      ...style?.container,
    },
    menu: {
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      zIndex: '100',
      minWidth: '198px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      color: '#000000',
      fontSize: '14px',
      transform: align === 'left' ? 'translate(-100%)' : 'inherit',
      ...style?.menu,
    },
    menuButton: {
      padding: '16px',

      fontWeight: 400,
      ...theme.components.clickable,
      ...style?.menuButton,
    },
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <div style={styles.container} ref={wrapperRef}>
      <div style={styles.menu}>
        {items.map((item) => (
          <div
            key={item?.label}
            onMouseDown={(event) => {
              console.log(event.target);
              event.stopPropagation();
              onClick(item);
            }}
            className="menu-button"
            style={styles.menuButton}
          >
            {item?.label}
          </div>
        ))}
      </div>
    </div>
  );
};

ActionMenu.defaultProps = {
  onSelect: () => {},
  items: [],
  onClose: () => {},
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActionMenu);
