import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-jss';

import { ReactComponent as SmallCross } from '../../assets/icons/small_cross.svg';

export const ImageUpload = ({
  style,
  label,
  disabled,
  value,
  loading = false,
  placeholder = 'Choose an image',
  placeholderImage,
  onChange = () => {},
}) => {
  const theme = useTheme();

  const ref = useRef(null);

  const handleOnChange = (event) => {
    // Can't change if not editable
    if (disabled) {
      return;
    }

    // Assuming only image
    console.log('e', event);

    const file = event?.target?.files[0];
    console.log('files', file);

    if (file === undefined) {
      return;
    }

    const imageName = file?.name.substring(0, file?.name.lastIndexOf('.')); // everything up to the format
    const imageType = file?.type.split('/')[1];

    // Upload image
    const newValue = {
      name: imageName,
      format: imageType,
      size: file?.size,
      readData: file,
      height: null,
      width: null,
      data: null,
    };

    onChange(newValue);
  };

  // Fetch the data from the image
  useEffect(() => {
    if (value?.readData) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        const imageData = e.target.result;
        image.src = e.target.result;

        image.onload = (e2) => {
          const { width, height } = e2.target;
          onChange({
            ...value,
            height,
            width,
            data: imageData,
          });
        };
      };

      reader.readAsDataURL(value?.readData);
    }
  }, [value?.readData]);

  const removeImage = () => {
    onChange(null);
  };

  const styles = {
    container: {
      ...style,
      position: 'relative',
      width: '100%',
      backgroundColor: '#FFFFFF',
      // zIndex: 5,
      ...style?.container,
    },
    label: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '19px',
      color: '#333333',
      marginBottom: '8px',
      ...style?.label,
    },
    link: {
      ...style?.link,
    },
    fileInput: {
      color: 'blue',
      cursor: 'pointer',
      ...style?.fileInput,
    },
    button: {
      ...style?.button,
    },
    text: {
      color: '#2626CD',
      cursor: 'pointer',
      userSelect: 'none',
      transitionDuration: '0.1s',
      fontSize: '14px',
      ...style?.text,
    },
    removeImageButton: {
      color: '#323232',
      paddingTop: '4px',
      ...theme.components.clickable,
      ...style?.removeImageButton,
    },
  };

  return (
    <div style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}

      <div style={styles.button}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <label
            style={styles.text}
            htmlFor="file-upload"
            className="custom-file-upload text-link-container"
          >
            {value?.readData?.name ? value?.readData?.name : placeholder}
          </label>
          {value && <SmallCross style={styles.removeImageButton} onClick={removeImage} />}
        </div>
        <input
          id="file-upload"
          type="file"
          disabled={disabled}
          onChange={handleOnChange}
          accept=".jpg,.jpeg,.png,.webp,.jfif"
        />
        {(value?.data || placeholderImage) && (
          <img
            src={value?.data?.url || value?.data || placeholderImage}
            alt="Game's hero art"
            style={{ maxWidth: '100%', marginTop: '16px', maxHeight: '200px' }}
          />
        )}
      </div>
    </div>
  );
};
