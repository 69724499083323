import './styles.css';

import * as Avatar from '@radix-ui/react-avatar';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Box, Flex, Heading, IconButton, Separator, Text } from '@radix-ui/themes';
import React, { useEffect, useMemo, useState } from 'react';
// import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { useIsMobile } from '../../utils/hooks/useIsMobile';
import { useStyles } from './SidebarStyles.ts';

// import { closeSidebar } from '../utils';
// import ColorSchemeToggle from './ColorSchemeToggle';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        style={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          // '& > *': {
          //   overflow: 'hidden',
          // },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

const openSidebar = () => {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
};

const closeSidebar = () => {
  console.log('closing sidebar', document.documentElement.style);
  if (typeof document !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
};

const Sidebar = () => {
  const classes = useStyles();
  const sidebarWidth = 224;
  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState(!isMobile);

  console.log('use is mobile', isMobile);

  // Change behaviour if mobile / desktop
  useEffect(() => {
    setIsOpen(true);
    openSidebar();

    // Auto hide on mobile
    if (isMobile) {
      setIsOpen(false);
      closeSidebar();
    }
  }, [isMobile]);

  const NavMenuLink = ({ to, ...props }: { to: string }) => {
    // const pathname = usePathname();
    // const isActive = href === pathname;

    return (
      <NavigationMenu.Link asChild active={true}>
        <Link to={to} className="NavigationMenuLink" {...props} />
      </NavigationMenu.Link>
    );
  };

  return (
    <Box
      style={{
        position: 'relative',
        width: isOpen ? sidebarWidth : 0,
        transition: 'transform 0.4s, width 0.4s, marginLeft 0.4s',
        zIndex: 100,
        height: '100dvh',
        // backgroundColor: 'white',
        flexShrink: 0,
        display: 'flex',
      }}
    >
      <Box
        className="Sidebar"
        style={{
          position: 'absolute',
          // position: {
          //   xs: 'fixed',
          //   md: 'sticky',
          // },
          // transform: {
          //   xs: `translateX(calc(100% * (${sidebarWidth} - 1)))`,
          //   md: 'none',
          // },

          transition: 'transform 0.4s, width 0.4s, left 0.4s',
          // zIndex: 100,
          height: '100dvh',
          width: sidebarWidth,
          left: isOpen ? 0 : `-${sidebarWidth}px`,
          top: 0,
          // p: 2,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRight: '1px solid',
          // borderColor: 'divider',
        }}
      >
        {/*<Box*/}
        {/*  style={{*/}
        {/*    position: 'fixed',*/}
        {/*    zIndex: 9998,*/}
        {/*    top: 0,*/}
        {/*    left: 0,*/}
        {/*    width: '100vw',*/}
        {/*    height: '100vh',*/}
        {/*    opacity: 'var(--SideNavigation-slideIn)',*/}
        {/*    backgroundColor: 'var(--joy-palette-background-backdrop)',*/}
        {/*    transition: 'opacity 0.4s',*/}
        {/*    // transform: {*/}
        {/*    //   xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',*/}
        {/*    //   lg: 'translateX(-100%)',*/}
        {/*    // },*/}
        {/*  }}*/}
        {/*  onClick={() => closeSidebar()}*/}
        {/*/>*/}
        <Box style={{ display: 'flex', gap: 1, alignItems: 'center', padding: '0 20px' }}>
          <Heading as="h2">Baby food</Heading>
          {/*<ColorSchemeToggle sx={{ ml: 'auto' }} />*/}
        </Box>

        <Box
          style={{
            minHeight: 0,
            overflow: 'hidden auto',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            // [`& .${listItemButtonClasses.root}`]: {
            //   gap: 1.5,
            // },
          }}
        >
          <NavigationMenu.Root orientation={'vertical'} className={classes.root}>
            <NavigationMenu.List className={classes.list}>
              {/*<NavigationMenu.Item>*/}
              {/*  <NavigationMenu.Trigger>Item one</NavigationMenu.Trigger>*/}
              {/*  /!*<NavigationMenu.Content>Item one content</NavigationMenu.Content>*!/*/}
              {/*</NavigationMenu.Item>*/}

              <NavigationMenu.Item>
                {/*<NavigationMenu.Trigger>Home</NavigationMenu.Trigger>*/}
                {/*<NavigationMenu.Content>*/}
                {/*  Home*/}

                <NavigationMenu.Link active>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                    Home
                  </Link>
                </NavigationMenu.Link>
                {/*<NavigationMenu.Link>Home</NavigationMenu.Link>*/}
                {/*</NavigationMenu.Content>*/}
              </NavigationMenu.Item>

              {/*<NavigationMenu.Item>*/}
              {/*  <NavigationMenu.Link>*/}
              {/*    <Link to="/home2">Test page</Link>*/}
              {/*  </NavigationMenu.Link>*/}
              {/*</NavigationMenu.Item>*/}

              <NavigationMenu.Item>
                <NavigationMenu.Trigger />
                <NavigationMenu.Content>
                  <NavigationMenu.Sub>
                    <NavigationMenu.List />
                    <NavigationMenu.Viewport />
                  </NavigationMenu.Sub>
                </NavigationMenu.Content>
              </NavigationMenu.Item>

              <NavigationMenu.Indicator className="NavigationMenuIndicator" />
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </Box>

        <Separator my="3" size="4" />
        <Flex>
          <Avatar.Root>
            {/*<Avatar.Image*/}
            {/*  src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?&w=256&h=256&q=70&crop=focalpoint&fp-x=0.5&fp-y=0.3&fp-z=1&fit=crop"*/}
            {/*  alt="Cameron"*/}
            {/*  style={{*/}
            {/*    maxWidth: '50px',*/}
            {/*    maxHeight: '50px',*/}
            {/*    aspectRatio: 1,*/}
            {/*    borderRadius: 'inherit',*/}
            {/*    objectFit: 'cover',*/}
            {/*  }}*/}
            {/*/>*/}
            {/*<Avatar.Fallback delayMs={600}>C</Avatar.Fallback>*/}
          </Avatar.Root>

          <Box style={{ minWidth: 0, flex: 1 }}>
            {/*<Text>{user?.data?.email}</Text>*/}
            {/*<Typography level="body-xs">fake@example.com</Typography>*/}
          </Box>
          <Link to={'/logout'}>Log out</Link>
        </Flex>
      </Box>
    </Box>
  );
};

// @ts-ignore
const mapStateToProps = (state) => ({
  user: state.user,
});

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
