import { useEffect, useState } from 'react';

// I wonder if hooks can be made into singletons?
// TODO define breakpoints
export const useIsMobile = () => {
  const checkIsMobile = (width) => {
    return width < 600;
  };

  const [isMobile, setIsMobile] = useState(checkIsMobile(window.innerWidth));

  //choose the screen size
  const handleResize = () => {
    setIsMobile(checkIsMobile(window.innerWidth));
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return isMobile;
};
