// import { Box, Flex, Heading, IconButton, Separator, Text } from '@radix-ui/themes';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { useIsMobile } from '../../utils/hooks/useIsMobile';
import { useStyles } from './MobileNavigationStyles.ts';

// TODO use radix navmenu as well
const MobileNavigation = () => {
  const classes = useStyles();

  return (
    <div className={classes.mobileNavigation}>
      <div>Home</div>
      {/*<div>Something</div>*/}
      <div>Log out</div>
    </div>
  );
};

// @ts-ignore
const mapStateToProps = (state) => ({});

// @ts-ignore
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
