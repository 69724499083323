import { createFoodCategory, fetchFoodCategories, updateFoodCategory } from '../apiClient';
import { beginAction, handleFailure, handleResponse, showMessageOnSuccess } from './actions';
import * as type from './types';

export const fetchFoodCategoriesAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_FOOD_CATEGORIES_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchFoodCategories(includes, filters);

        if (response.errors.length > 0) {
          console.log('FETCH_FOOD_CATEGORIES_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_FOOD_CATEGORIES_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_FOOD_CATEGORIES_SUCCESS', response);
          dispatch({
            type: type.FETCH_FOOD_CATEGORIES_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_FOOD_CATEGORIES_FAILURE', error);
        dispatch({
          type: type.FETCH_FOOD_CATEGORIES_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

export const createFoodCategoryAction = (foodCategoryData) => (dispatch) => {
  console.log('preparing to create foodCategory', foodCategoryData);

  dispatch({
    type: type.CREATE_FOOD_CATEGORY_PENDING,
  });

  const postData = async () => {
    try {
      let response = null;

      console.log('creating foodCategory', foodCategoryData);

      response = await createFoodCategory({
        name: foodCategoryData?.name,
        color: foodCategoryData?.color,
      });

      if (response.errors.length > 0) {
        console.log('CREATE_FOOD_CATEGORY_FAILURE', response.errors);
        dispatch({
          type: type.CREATE_FOOD_CATEGORY_FAILURE,
          payload: response.errors,
        });
      } else {
        console.log('CREATE_FOOD_CATEGORY_SUCCESS', response);
        dispatch({
          type: type.CREATE_FOOD_CATEGORY_SUCCESS,
          payload: response?.data,
        });

        dispatch({
          type: type.CREATE_NOTIFICATION,
          payload: {
            message: 'Category successfully created',
            severity: 'success',
            id: `CREATE_FOOD_CATEGORY_SUCCESS_${new Date().getTime()}`,
          },
        });
      }
    } catch (error) {
      console.log('CREATE_FOOD_CATEGORY_FAILURE', error);
      dispatch({
        type: type.CREATE_FOOD_CATEGORY_FAILURE,
        payload: [error.message],
      });
    }
  };

  postData();
};

export const updateFoodCategoryAction = (foodCategoryId, foodCategoryData) => (dispatch) => {
  console.log('preparing to update foodCategory', foodCategoryData);

  beginAction('UPDATE_FOOD_CATEGORY', dispatch);

  (async () => {
    try {
      let response = null;

      const newFoodCategoryData = {
        name: foodCategoryData?.name,
        color: foodCategoryData?.color,
      };

      // Now update the foodCategory
      response = await updateFoodCategory(newFoodCategoryData, foodCategoryId);
      handleResponse(response, 'UPDATE_FOOD_CATEGORY', dispatch);
      showMessageOnSuccess(
        response,
        'Category successfully updated',
        type.UPDATE_FOOD_CATEGORY_SUCCESS,
        dispatch,
      );
    } catch (error) {
      handleFailure(error, 'UPDATE_FOOD_CATEGORY', dispatch);
    }
  })();
};
