import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ReactComponent as DownArrow } from '../../assets/down_arrow_filled.svg';
import { detectClickOutside } from '../../helpers/helpers';
import { ActionMenu } from './ActionMenu';

export const ActionMenuButton = ({ style, items, label, disabled, loading, onSelect }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  detectClickOutside(wrapperRef, () => setOpen(false));

  const styles = {
    container: {
      ...style,
      position: 'relative',
      fontWeight: 400,
      ...style?.container,
    },
  };

  return (
    <div
      style={{ ...styles.status, ...theme.components.clickable }}
      onClick={(e) => {
        setOpen(true);
        e.preventDefault();
        e.stopPropagation();
      }}
      // onFocus={() => setShowStatusMenu(!showStatusMenu)}
      tabIndex={0}
      onBlur={() => setOpen(false)}
    >
      <div
        style={{
          color: '#323232',
          marginTop: '-1px',
        }}
      >
        <DownArrow />
      </div>

      <ActionMenu align={'left'} isOpen={open} onClose={() => setOpen(false)} items={items} />
    </div>
  );
};

ActionMenuButton.defaultProps = {
  onSelect: () => {},
  items: [],
  onClose: () => {},
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActionMenuButton);
