import React, { useEffect, useRef } from 'react';

export const TextField = ({
  style,
  label,
  disabled,
  value,
  type,
  loading,
  placeholder,
  onChange,
  onBlur,
  onEnter,
  autofocus,
}) => {
  const ref = useRef();
  const handleOnChange = (event) => {
    // Can't change if not editable
    if (disabled) {
      return;
    }

    const newValue = event.target.value;

    onChange(newValue);
  };

  // Auto focus
  useEffect(() => {
    // The timeout is because inputs revealed on click (e.g. in modals) have
    // their inputs stolen by the click
    if (ref.current && autofocus) {
      setTimeout(() => {
        console.log('focusing');
        ref.current.focus();
      }, 50);
    }
  }, []);

  const styles = {
    container: {
      ...style,
      position: 'relative',
      width: '100%',
      ...style?.container,
    },
    label: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      marginBottom: '8px',
      ...style?.label,
    },
    text: {
      ...style?.text,
    },
  };

  return (
    <div style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}
      <input
        ref={ref}
        onBlur={onBlur}
        autoCorrect="off"
        spellCheck="off"
        autoCapitalize="words"
        className="text-input"
        type={type}
        value={value === null ? '' : value}
        placeholder={placeholder}
        onChange={handleOnChange}
        tabIndex={0}
        disabled={disabled}
        style={styles.text}
      />
    </div>
  );
};

TextField.defaultProps = {
  type: 'text',
  loading: false,
  placeholder: 'Start typing...',
  onChange: () => {},
  onBlur: () => {},
  onEnter: () => {},
  autofocus: false,
};
