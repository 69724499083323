import React from 'react';

import { ReactComponent as CheckboxFilled } from '../../assets/icons/checkbox_filled.svg';
import { ReactComponent as CheckboxOutline } from '../../assets/icons/checkbox_outline.svg';

export const Checkbox = ({ label, value, style, onClick, disabled, loading }) => {
  const styles = {
    container: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      userSelect: 'none',
      transitionDuration: '0.1s',
      color: '#000000',
      ...style?.container,
    },
    label: {
      fontSize: '14px',
      lineHeight: '24px',
      ...style?.text,
    },
    checkboxOuter: {
      height: '24px',
      width: '24px',
      color: '#323232',
      ...style?.checkboxOuter,
    },
    checked: {
      color: '#2626CD',
      ...style?.checkboxOuter,
    },
    unchecked: {
      color: '#323232',
      ...style?.checkboxOuter,
    },
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (disabled || loading) {
      return;
    }

    onClick(label, value);
  };

  return (
    <div className="checkbox-container" style={styles.container} onClick={handleClick}>
      <div style={styles.checkboxOuter}>
        {value ? (
          <CheckboxFilled style={styles.checked} />
        ) : (
          <CheckboxOutline style={styles.unchecked} />
        )}
      </div>
      <div style={styles.label}>{label}</div>
    </div>
  );
};
