import { flattenToProperty, pluck } from '../../helpers/helpers';
import { createFoodList, deleteFoodList, fetchFoodLists, updateFoodList } from '../apiClient';
import * as type from './types';

export const fetchFoodListsAction =
  (includes = [], filters = []) =>
  (dispatch) => {
    dispatch({
      type: type.FETCH_FOOD_LISTS_PENDING,
    });

    (async () => {
      let response = null;

      try {
        response = await fetchFoodLists(includes, filters);

        if (response.errors.length > 0) {
          console.log('FETCH_FOOD_LISTS_FAILURE', response.errors);
          dispatch({
            type: type.FETCH_FOOD_LISTS_FAILURE,
            payload: response.errors,
          });
        } else {
          console.log('FETCH_FOOD_LISTS_SUCCESS', response);
          dispatch({
            type: type.FETCH_FOOD_LISTS_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (error) {
        console.log('FETCH_FOOD_LISTS_FAILURE', error);
        dispatch({
          type: type.FETCH_FOOD_LISTS_FAILURE,
          payload: [error.message],
        });
      }
    })();
  };

/**
 * Update many food lists in one go
 *
 * NewFoodLists - the updated list including new indexes
 * OldFoodLists - the old values before updating. Items from this not in the new list will be deleted
 */
export const bulkUpdateFoodListsAction = (newFoodLists, oldFoodLists) => (dispatch) => {
  dispatch({
    type: type.BATCH_UPDATE_FOOD_LISTS_PENDING,
  });

  (async () => {
    const response = null;

    try {
      console.log('new food lists', newFoodLists);
      console.log('old food lists', oldFoodLists);

      const oldFoodListIds = flattenToProperty(oldFoodLists, 'id');
      const newFoodListIds = flattenToProperty(newFoodLists, 'id');

      const foodListsToCreate = newFoodLists?.filter(
        (newFoodList) => !oldFoodListIds?.includes(newFoodList?.id),
      );

      const foodListsToUpdate = newFoodLists?.filter((newFoodList) =>
        oldFoodListIds?.includes(newFoodList?.id),
      );

      const foodListsToDelete = oldFoodLists?.filter(
        (oldFoodList) => !newFoodListIds?.includes(oldFoodList?.id),
      );

      console.log('create', foodListsToCreate);
      console.log('update', foodListsToUpdate);
      console.log('delete', foodListsToDelete);

      const createResponse = await Promise.all(
        foodListsToCreate?.map((newFoodList) =>
          createFoodList({
            index: newFoodList?.index,
            name: newFoodList?.value,
            is_shared: false,
          }),
        ),
      );

      const createErrors = createResponse?.filter((response) => response?.errors?.length > 0);
      if (createErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_FOOD_LISTS_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      const updateResponse = await Promise.all(
        foodListsToUpdate?.map((foodList) =>
          updateFoodList(
            {
              index: foodList?.index,
              name: foodList?.value,
            },
            foodList?.id,
          ),
        ),
      );

      const updateErrors = updateResponse?.filter((response) => response?.errors?.length > 0);
      if (updateErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_FOOD_LISTS_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      const deleteResponse = await Promise.all(
        foodListsToDelete?.map((foodList) => deleteFoodList(foodList?.id)),
      );

      const deleteErrors = deleteResponse?.filter((response) => response?.errors?.length > 0);
      if (deleteErrors?.length > 0) {
        dispatch({
          type: `BATCH_UPDATE_FOOD_LISTS_FAILURE`,
          payload: response.errors,
        });
        return;
      }

      // If we made it here, it worked

      // console.log("BATCH_UPDATE_FOOD_LISTS_SUCCESS", createResponse, updateResponse, deleteResponse);

      dispatch({
        type: type.CREATE_NOTIFICATION,
        payload: {
          message: 'Food lists successfully updated',
          severity: 'success',
          id: `BATCH_UPDATE_FOOD_LISTS_SUCCESS_${new Date().getTime()}`,
        },
      });

      dispatch({
        type: type.BATCH_UPDATE_FOOD_LISTS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.log('BATCH_UPDATE_FOOD_LISTS_FAILURE', error);
      dispatch({
        type: type.BATCH_UPDATE_FOOD_LISTS_FAILURE,
        payload: [error.message],
      });
    }
  })();
};
