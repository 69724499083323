import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateFeedAttemptAction } from '../../data/actions/feedAttemptsAction';
import { Checkbox } from '../Checkbox/Checkbox';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { TextArea } from '../TextArea/TextArea';
import { Modal } from './Modal';

const EditFeedAttemptModal = ({
  style,
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  updateFeedAttempt,
  feedAttempts,
  feedAttempt,
}) => {
  const [image, setImage] = useState(null);
  const [hadReaction, setHadReaction] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(true);
  const [comment, setComment] = useState('');

  const [imageError, setImageError] = useState('');
  const [commentError, setCommentError] = useState('');

  const [posting, setPosting] = useState(false);

  const validate = () => {
    // TODO validation fields
    let valid = true;

    console.log('image', image);
    const imageUploadLimit = 100 * 1000 * 1000; // 100MB TODO set this in env
    if (image && !image?.size >= imageUploadLimit) {
      setImageError('File size must be less than 100MB');
      valid = false;
    }

    if (!valid) {
      console.log('form is not valid');
    }

    return valid;
  };

  // When opening, reset the state
  useEffect(() => {
    if (isOpen) {
      // setImage(feedAttempt?.image);
      setComment(feedAttempt?.comment);
      setHadReaction(feedAttempt?.had_reaction);
      setWasSuccessful(feedAttempt?.was_successful);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const valid = validate();

    if (!valid) {
      console.error('Failed validation');
      return;
    }

    // TODO only update the fields that are different
    updateFeedAttempt(
      {
        // eslint-disable-next-line camelcase
        had_reaction: hadReaction,
        // eslint-disable-next-line camelcase
        was_successful: wasSuccessful,
        comment: comment,
      },
      feedAttempt?.id,
    );

    setPosting(true);
  };

  // Close the modal when the feedAttempt is finished posting
  useEffect(() => {
    if (posting && !feedAttempts?.posting && feedAttempts?.success) {
      setPosting(false);
      closeModal();
    }
  }, [feedAttempts?.posting, feedAttempts?.success]);

  const isDisabled = () => feedAttempts?.posting;

  const closeModal = () => {
    unstable_batchedUpdates(() => {
      setWasSuccessful(true);
      setImage(null);
      setComment('');
      setHadReaction(false);
      setImageError('');
      setPosting(false);
      setPosting(false);
    });
    onClose();
  };

  return (
    <Modal
      showCloseButton
      showCancelButton
      title="Edit feed attempt"
      submitText="Save changes"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      disabled={isDisabled()}
      loading={feedAttempts?.posting}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          Viewing attempt from {dayjs(feedAttempt?.created_at).format('DD/MM/YYYY HH:mm')}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Checkbox
            label="Successfully ate"
            style={{ maxWidth: '400px' }}
            value={wasSuccessful}
            onClick={() => setWasSuccessful(!wasSuccessful)}
          />
          <Checkbox
            label="Had an allergic reaction"
            style={{ maxWidth: '400px' }}
            value={hadReaction}
            onClick={() => setHadReaction(!hadReaction)}
          />
          <ImageUpload
            value={image || feedAttempt?.image}
            placeholderImage={feedAttempt?.image}
            onChange={setImage}
            label="Pic of the attempt"
            style={{ maxWidth: '400px' }}
            error={imageError}
          />
          <TextArea
            label="Comments"
            placeholder="Type your comments here..."
            value={comment}
            onChange={setComment}
            error={commentError}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  feedAttempts: state.feedAttempts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFeedAttempt: updateFeedAttemptAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditFeedAttemptModal);
