export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    // Ignore write errors.
  }
};

export const loadFromLocalStorage = (key, defaultValue = null) => {
  try {
    return localStorage.getItem(key) ?? defaultValue;
  } catch (err) {
    // Ignore write errors.
    return defaultValue;
  }
};
