import React from 'react';

type PropTypes = {
  children?: React.ReactNode;
};

export const LoginLayout = ({ children }: PropTypes) => (
  <div className="app-layout" style={{ minHeight: '100dvh' }}>
    <div
      style={{
        paddingTop: '100px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'calc(100% - 48px)',
        // minWidth: "calc(100% - 48px)",
        maxWidth: '1448px',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  </div>
);
