import { createUseStyles } from 'react-jss';

import { ThemeType } from '../../theme.ts';

export const useStyles = createUseStyles(
  (theme: ThemeType) => ({
    mobileNavigation: {
      backgroundColor: theme.almostWhite,
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '42px',
      borderTop: '1px solid black',
      width: '100dvw',
      marginTop: '42px', // So it doesn't overlap page content at bottom
      padding: '12px',
      zIndex: '100',
      transition: 'bottom 0.4s',

      ['@media only screen and (min-width: 600px)']: {
        // display: 'none',
        bottom: '-42px',

        animation: 'hideAnimation 0s ease-in 1s forwards',
        animationFillMode: 'forwards',
      },
    },
    root: {
      backgroundColor: theme.almostWhite,
      // backgroundColor: var(),
      position: 'relative',
      display: 'flex',
      // justifyContent: 'center',
      // width: '100vw',
      zIndex: '1',
    },
    list: {
      display: 'flex',
      // backgroundColor: 'white',
      // borderRadius: '6px',
      listStyle: 'none',
      padding: '0 20px',
      // boxShadow: '0 2px 10px var(--black-a7)',
      margin: '0',
    },
  }),
  { name: 'MobileNavigation' },
);
